<template>
  <div>
    <div id="items-direction" style="position: absolute; top: 0"></div>
    <main
      id="baomoi-content"
      class="baomoi-content single-post-wrap"
      role="main"
    >
      <div class="single-post-wrap">
        <div class="container">
          <div class="row">
            <div class="col-md-12 single-content">
              <div class="content-wrap">
                <p class="h1">
                  Ai đang mất ngủ đọc ngay bí quyết hỗ trợ ngủ ngon mỗi đêm
                </p>

                <div class="bm-excerpt">
                  <div class="bm-sub-excerpt">
                    <p>
                      "CÓ SỨC KHỎE SẼ CÓ HÀNG TRĂM ĐIỀU ƯỚC<br />
                      KHÔNG CÓ SỨC KHỎE CHỈ CÓ DUY NHẤT 1 ĐIỀU ƯỚC LÀ CÓ SỨC
                      KHỎE"
                    </p>
                  </div>
                  <p>
                    Đó là chia sẻ thật lòng của bác Lê Thị Bẩy (66 tuổi, tỉnh Hà
                    Nam). Đã hơn 3 năm nay, bác bị mất ngủ “ghé thăm”, một đêm
                    chỉ ngủ được 1-2 tiếng, có đêm thức trắng. Lúc nào cũng lơ
                    mơ, không tỉnh táo, lại còn hay cáu gắt. Người thì gầy đi
                    trông thấy. May mắn thay, nhờ một người cháu giới thiệu bí
                    quyết, giờ đây bác đã có thể vui vẻ niềm vui tuổi già với
                    con cháu, tìm lại giấc ngủ ngon mỗi đêm.
                  </p>
                </div>
                <div class="bm-main-content">
                  <p class="bm-heading">
                    Sức khỏe đi xuống rõ rệt vì “không ngủ được”
                  </p>
                  <p>
                    Nhìn khuôn dung tươi tỉnh, hồng hào, thao tác nhanh nhẹn của
                    bác Bẩy, không ai tin nổi trước đây bác từng sút gần 8kg vì
                    mất ngủ triền miên, sức khỏe kém đi rõ rệt.
                  </p>
                  <img
                    src="../../../assets/images/thoa-1.jpg"
                    alt="Bác Bẩy từng trải qua thời gian vật lộn với mất ngủ"
                    title="Bác Bẩy từng trải qua thời gian vật lộn với mất ngủ"
                  />
                  <p class="sub-img">
                    Bác Bẩy từng trải qua thời gian "vật lộn" với mất ngủ
                  </p>
                  <p>
                    Bác Bẩy tâm sự: “Bác là giáo viên về hưu đã lâu, trước nay
                    đều cảm thấy may mắn vì sức khỏe ổn định, không ốm đau hay
                    bệnh tật gì. Ngoài thời gian chăm lo cho gia đình, bác vẫn
                    thường xuyên tập thể dục hàng ngày cùng các cô bạn trong
                    cùng xóm.
                  </p>
                  <p>
                    Thế nhưng, niềm vui tuổi già không được trọn vẹn khi bác bị
                    mất ngủ từ hơn 3 năm trước. Ngày xưa, cũng thỉnh thoảng có
                    vài đêm trằn trọc không ngủ được, nhưng bác chỉ nghĩ là do
                    thời tiết, hoặc do mình ngủ trưa nhiều. Biết là đến tuổi
                    này, ai mà chả bị mất ngủ. Thế nhưng, đến khi bản thân mình
                    bị, mới hiểu được nỗi khổ mà nó gây ra.
                  </p>
                  <p>
                    Thời gian đầu, bác chỉ bị khó vào giấc thôi. Có bữa nằm trằn
                    trọc hơn 1 tiếng đồng hồ mới ngủ được. Cứ xoay bên nọ, xoay
                    bên kia, loay hoay mãi nhưng không hiệu quả. Bác trai thì
                    ngủ tốt, nên bác sợ ảnh hưởng lắm! Cũng do vẫn ngủ được một
                    mạch tới sang, nên mình chủ quan, coi thường nó.
                  </p>
                  <img
                    src="../../../assets/images/chart.jpg"
                    title="Ngủ dưới 5 tiếng một ngày mang nhiều nguy cơ tiềm ẩn"
                    alt="Những nguy cơ khi ngủ dưới 5 tiếng một ngày"
                  />
                  <p class="sub-img">
                    Những nguy cơ khi ngủ dưới 5 tiếng một ngày
                  </p>
                  <p>
                    Bác nhớ là sau khoảng 2 tháng như thế, bác bắt đầu khó vào
                    giấc ngủ hơn. Giấc ngủ không còn được sâu, lại rất dễ tỉnh
                    dậy. Nhiều hôm bác lên giường từ sớm, nhưng nằm mãi mới bắt
                    đầu lơ mơ ngủ. Mình ngủ mà vẫn rất tỉnh, chỉ ai khẽ động gì
                    là có thể dậy ngay, không ngủ lại được. Trung bình một tối
                    bác chỉ chợp mắt được một chút. Khổ lắm mà không ai hiểu cho
                    mình! Nằm trằn trọc suy nghĩ đủ thứ trên đời, có những hôm
                    nước mắt rơi vì nghĩ lại những chuyện ngày xưa…
                  </p>
                  <p>
                    Từ ngày mất ngủ, sức khỏe đi xuống trầm trọng. Người lúc nào
                    cũng uể oải do thiếu ngủ. Đi tập thể dục nhiều khi còn chẳng
                    muốn giơ tay lên nữa, bị người ta nhắc hoài nên bác cũng
                    nghỉ luôn. Các con về chơi, thấy mẹ mệt mỏi quá, mới dám kể
                    vì sợ ảnh hưởng tới công việc của chúng nó. Cũng theo chúng
                    nó đi khám khắp nơi, mà kết luận đều là mất ngủ vì tuổi già
                    chứ không có nguyên nhân cụ thể. Cũng dùng đủ các biện pháp,
                    hỏi đủ người, mà cũng chẳng hiệu quả. Chỉ khi nào có việc gì
                    cần, thì mới đi ra khỏi nhà, sợ đi ra người ta lại quở, sao
                    dạo này gầy gò, ốm yếu thế”.
                  </p>
                  <div class="contact-box">
                    <div class="w-100 box-wrap">
                      <p>
                        <i class="fas fa-phone"></i> Gọi ngay Hotline
                        <a href="tel:0363166357">0363.166.357</a>
                        hoặc để lại số điện thoại để được Chuyên gia tư vấn
                        chuyên sâu về tình trạng mất ngủ bạn đang gặp phải.
                      </p>
                      <form
                        action="/#wpcf7-f15-o1"
                        method="post"
                        class="wpcf7-form init"
                        novalidate="novalidate"
                        data-status="init"
                      >
                        <div class="w-70 contact-left">
                          <span class="wpcf7-form-control-wrap sdt"
                            ><input
                              type="number"
                              name="sdt"
                              value=""
                              min="99999999"
                              max="9999999999"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Số điện thoại"
                              v-model="formRegisterIntro.PhoneNumberIntro"
                          /></span>
                        </div>
                        <div
                          class="btn-advice-footer"
                          @click="createWebRegisterIntro"
                        >
                          <span role="status" aria-hidden="true"></span>
                          Đăng ký tư vấn
                        </div>
                        <p
                          v-if="isShowWarning"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentWarning }}
                        </p>
                        <p
                          v-if="isShowSuccess"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentSuccess }}
                        </p>
                      </form>
                    </div>
                  </div>
                  <p class="bm-heading">
                    May mắn tìm được bí quyết ngủ ngon nhờ người cháu
                  </p>
                  <p>
                    Một hôm, có đứa cháu họ đến chơi. Nó học ở nước ngoài về,
                    hiểu biết lắm, nên bác mới bấm bụng hỏi xem có cách nào chữa
                    mất ngủ không? May sao, cháu nó nói nó cũng bị mất ngủ hơn
                    một năm, nhưng nay đã ngủ lại theo nhịp sinh học bình thường
                    rồi. Nó nói, cháu còn trẻ, nhưng do một thời gian thức đêm
                    làm luận án, nên bị mất ngủ. Cháu tìm trên mạng thấy sản
                    phẩm
                    <a href="/" style="font-weight: bold">SIX.am</a>, rồi ra nhà
                    thuốc mua một liệu trình về uống. Sau liệu trình, cháu đã
                    cải thiện được giấc ngủ hàng đêm luôn bác ạ, bác thử tìm mua
                    xem.
                  </p>
                  <img
                    src="../../../assets/images/hop-1.jpg"
                    title="SIX.am sản phẩm đặc trị mất ngủ"
                    alt="Sản phẩm SIX.am dựa trên bằng Độc quyền sáng chế cấp Quốc gia hoạt chất Rotundin Sulfat"
                  />
                  <p class="sub-img">
                    Sản phẩm SIX.am dựa trên bằng Độc quyền sáng chế cấp Quốc
                    gia hoạt chất Rotundin Sulfat
                  </p>
                  <p>
                    Như bắt được vàng, bác lên mạng tìm hiểu ngay về sản phẩm
                    <a href="/" style="font-weight: bold">SIX.am</a>. Thấy sản
                    phẩm của công ty uy tín, lại dựa trên bằng Độc quyền sáng
                    chế cấp Quốc gia, đã được xuất khẩu ra một số nước trong khu
                    vực, nên bác cũng thấy yên tâm, nên đặt lịch Chuyên gia tư
                    vấn. Bác tâm sự: "Dù mất ngủ nhưng nhất quyết không dám uống
                    linh tinh, vì mình phải biết chọn lọc những gì tốt nhất cho
                    sức khỏe của mình. Được sự tư vấn của chuyên gia bác mới
                    biết mình đã làm đúng, và giới thiệu cho bác sản phẩm
                    <b>SIX.am - Quà tặng giấc ngủ</b>. SIX.am có cơ chế tạo giấc
                    ngủ sinh lý tự nhiên. Vậy nên ngoài tác dụng ngắn hạn giúp
                    ngủ ngon mỗi đêm, thức dậy không mệt mỏi, SIX.am còn có tác
                    dụng dài hạn, giúp tạo thói quen ngủ sinh lý đúng giờ.
                  </p>
                  <img
                    src="../../../assets/images/thoa-2.jpg"
                    title="Lấy lại giấc ngủ ngon cùng SIX.am"
                    alt="Sau liệu trình sử dụng SIX.am phù hợp, bác Bẩy đã tìm lại được giấc ngủ ngon mỗi đêm cho mình"
                  />
                  <p class="sub-img">
                    Thấy bác Bẩy vui khỏe, ai cũng mừng cho bác. Thế mới thấy,
                    giấc ngủ quan trọng thế nào với mỗi chúng ta.
                  </p>
                  <p>
                    Đặc biệt, sản phẩm SIX.am còn có hoạt chất
                    <a
                      href="https://six-am.vn/rotundin-sulfat-trong-six-am-tu-cu-binh-voi-toi-hoat-chat-moi-trong-duoc-dien-viet-nam/"
                      style="font-weight: bold"
                      >Rotundin Sulfat</a
                    >
                    là dược chất độc quyền sáng chế, không có tác dụng phụ,
                    không gây phụ thuộc. Mặt khác lại không bị ảnh hưởng đến
                    bệnh lý huyết áp, tim mạch, tiểu đường mà người già hay mắc
                    phải. Bác vẫn ghi lại hết trong sổ tay đây này”.
                  </p>
                  <p>
                    Tin tưởng nên bác đã mua SIX.am về sử dụng, tuân thủ đúng
                    theo liệu trình mà bác được tư vấn. Thấy bác Bẩy vui khỏe,
                    ai cũng mừng cho bác. Thế mới thấy, giấc ngủ quan trọng thế
                    nào với mỗi chúng ta.
                  </p>
                  <div class="bm-form bm-dathang">
                    <p class="form-title">Đặt mua SIX.am</p>
                    <p class="form-info">- Giá bán: 379.000 đồng/hộp 20 ống</p>
                    <p class="form-info">
                      - Sử dụng 1-2 ống/tối trước khi đi ngủ 30-60 phút
                    </p>
                    <p class="form-info">- Rẻ hơn khi mua theo liệu trình:</p>
                    <ul>
                      <li>
                        Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                        300.000 đồng + Phí vận chuyển
                      </li>
                      <li>
                        Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000 đồng
                        (miễn phí ship)
                      </li>
                      <li>
                        Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000 đồng
                        => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1
                        combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu
                        chân răng (miễn phí ship)
                      </li>
                    </ul>
                    <form
                      action="/#wpcf7-f17-o4"
                      method="post"
                      class="wpcf7-form init"
                      novalidate="novalidate"
                      data-status="init"
                    >
                      <div class="w-100">
                        <span class="wpcf7-form-control-wrap hoten"
                          ><input
                            type="text"
                            name="hoten"
                            value=""
                            size="40"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Họ và tên"
                            v-model="formRegister.FullName"
                        /></span>
                      </div>
                      <div class="w-50 dathang-left itemNumber">
                        <span class="wpcf7-form-control-wrap sdt"
                          ><input
                            type="number"
                            name="sdt"
                            value=""
                            min="99999999"
                            max="9999999999"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Số điện thoại"
                            v-model="formRegister.PhoneNumber"
                        /></span>
                      </div>
                      <div class="w-50 itemSolusion">
                        <span class="wpcf7-form-control-wrap lieutrinh"
                          ><select
                            name="lieutrinh"
                            aria-required="true"
                            aria-invalid="false"
                            @change="onChange($event)"
                            v-model="selecteded"
                          >
                            <option value="" disabled selected>
                              Chọn liệu trình
                            </option>
                            <option
                              value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000 đồng (miễn phí ship)"
                            >
                              Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                              379.000 đồng (miễn phí ship)
                            </option>
                            <option
                              value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 758.000 đồng => Được giảm 70.000 đồng còn 688.000 đồng (miễn phí ship)"
                            >
                              Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000
                              đồng (miễn phí ship)
                            </option>
                            <option
                              value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000 đồng => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1 combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu chân răng (miễn phí ship)"
                            >
                              Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000
                              đồng => Được giảm 70.000 đồng còn 1.446.000 đồng +
                              tặng 1 combo nước súc miệng và kem đánh răng ngăn
                              ngừa chảy máu chân răng (miễn phí ship)
                            </option>
                          </select></span
                        >
                      </div>
                      <div class="w-100">
                        <span class="wpcf7-form-control-wrap diachi">
                          <textarea
                            name="diachi"
                            cols="40"
                            rows="10"
                            v-model="formRegister.Note"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Địa chỉ nhận hàng"
                          ></textarea>
                        </span>
                      </div>
                      <p
                        v-if="isShowWarning"
                        class="mt-2"
                        style="color: #fff; margin-bottom: 16px"
                      >
                        {{ contentWarning }}
                      </p>
                      <p
                        v-if="isShowSuccess"
                        class="mt-2"
                        style="color: #fff; margin-bottom: 16px"
                      >
                        {{ contentSuccess }}
                      </p>
                      <div @click="createWebRegister" class="btn-summit">
                        <span
                          role="status"
                          aria-hidden="true"
                          v-if="loadding"
                        ></span>
                        Đặt hàng
                      </div>
                    </form>
                  </div>
                  <p class="bm-heading">
                    Mất ngủ, khó ngủ, ngủ không sâu giấc - Hãy dùng SIX.am
                  </p>
                  <p>
                    Mất ngủ là tình trạng bệnh lý phổ biến, đặc biệt là ở người
                    cao tuổi. Theo Chuyên gia, có rất nhiều nguyên nhân gây ra
                    mất ngủ, chủ yếu đều đến từ các vấn đề về căng thẳng, lo âu,
                    trầm cảm hoặc rối loạn thần kinh thực vật.
                  </p>
                  <p>
                    Ở độ tuổi trên 45, một ngày ngủ trung bình dưới 5 tiếng được
                    xem là mất ngủ. Mất ngủ có nhiều triệu chứng như: Khó đi vào
                    giấc ngủ, ngủ không ngon giấc, giấc ngủ không sâu, dễ tỉnh
                    dậy… Mất ngủ làm tăng nguy cơ mắc các bệnh như tiểu đường,
                    đau tim, đột quỵ, mất trí nhớ v..v.. đặc biệt là ở người cao
                    tuổi.
                  </p>
                  <p>
                    Hiện nay, nhiều người có xu hướng sử dụng các sản phẩm nguồn
                    gốc thảo dược trong hỗ trợ điều trị mất ngủ, nổi bật trên
                    thị trường là sản phẩm
                    <a href="/" style="font-weight: bold">SIX.am</a>. Sản phẩm
                    dựa trên bằng độc quyền sáng chế cấp quốc gia hoạt chất
                    Rotundin Sulfat, ngoài ra còn có Saffron (Nhụy hoa nghệ
                    tây), cao khô vông nem, cao khô tâm sen – đều là các thảo
                    dược quý có tác dụng giúp hỗ trợ ngủ ngon, an thần.
                  </p>
                  <img
                    src="../../../assets/images/bangsangche.jpg"
                    title="SIX.am là sản phẩm có hoạt chất độc quyền sáng chế cấp quốc gia Rotundin Sulfat"
                    alt="SIX.am là sản phẩm có hoạt chất độc quyền sáng chế cấp quốc gia Rotundin Sulfat"
                  />
                  <p class="sub-img">
                    SIX.am là sản phẩm có hoạt chất độc quyền sáng chế cấp quốc
                    gia Rotundin Sulfat
                  </p>
                  <p>
                    <b>Hoạt chất Rotundin Sulfat</b> đã được thử nghiệm lâm sàng
                    trên 100 BN mất ngủ mãn. Kết quả lâm sàng đã chỉ ra:
                  </p>
                  <ul class="ul-nodot">
                    <li>
                      1. Thời gian vào giấc ngủ nhanh (15-35 phút sau sử dụng)
                      thời gian ngủ trung bình 315 phút (5.25h)
                    </li>
                    <li>
                      2. Dễ vào giấc ngủ và giấc ngủ sâu hơn, không mộng mị; Khi
                      ngủ dậy: bệnh nhân đều thấy thoải mái, không mệt.
                    </li>
                    <li>
                      3. Trên lâm sàng hoạt chất không gây ảnh hưởng tới chức
                      năng hệ tim mạch, điện tâm đồ không thay đổi.
                    </li>
                    <li>
                      4. Không thấy tác dụng phụ trong nhóm BN nghiên cứu.
                    </li>
                  </ul>
                  <div id="bm-banner-button" class="bm-banner-button">
                    <div class="button-wrap">
                      <a
                        target="_blank"
                        href="http://www.benhvien103.vn/nghien-cuu-tac-dung-gay-ngu-cua-rotundin-sulphat-duong-uong/"
                      >
                        <p style="text-align: center">Xem kết quả lâm sàng</p>
                      </a>
                    </div>
                  </div>
                  <div
                    id="bm-banner-button"
                    class="bm-banner-button"
                    style="margin-top: 0"
                  >
                    <div class="button-wrap">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/watch?v=vk3kGZiLI4E"
                      >
                        <p
                          style="
                            text-align: center;
                            font-size: 20px;
                            padding-left: 10px;
                            padding-right: 10px;
                          "
                        >
                          Phóng sự khoa học về Rotundin Sulfat
                        </p>
                      </a>
                    </div>
                  </div>
                  <p>
                    SIX.am là sản phẩm có hoạt chất độc quyền sáng chế cấp Quốc
                    gia này. Chính vì tác dụng của Rotundin Sulfat, SIX.am có
                    công dụng:
                  </p>
                  <ul class="ul-nodot">
                    <li>✅ Hỗ trợ an thần</li>
                    <li>✅ Hỗ trợ tạo giấc ngủ sinh lý tự nhiên</li>
                    <li>✅ Hỗ trợ ngủ sâu giấc</li>
                  </ul>
                  <p>Đối tượng nào nên sử dụng sản phẩm SIX.am?</p>
                  <ul>
                    <li>Người bị mất ngủ, khó ngủ</li>
                    <li>Ngủ không ngon giấc</li>
                    <li>Lo lắng, hồi hộp gây mất ngủ, trầm cảm</li>
                    <li>Đặc biệt mất ngủ ở người cao tuổi</li>
                  </ul>
                  <img
                    src="../../../assets/images/sudung.jpg"
                    title="Ai đang mất ngủ đọc ngay bí quyết hỗ trợ ngủ ngon mỗi đêm"
                    alt="Để lại sđt, Chuyên gia tư vấn cách dùng và liệu trình sử dụng SIX.am phù hợp cho bạn"
                  />
                  <p class="sub-img">
                    Để lại sđt, Chuyên gia tư vấn cách dùng và liệu trình sử
                    dụng SIX.am phù hợp cho bạn
                  </p>
                  <div class="contact-box">
                    <div class="w-100 box-wrap">
                      <p>
                        <i class="fas fa-phone"></i> Gọi ngay Hotline
                        <a href="tel:0363166357">0363.166.357</a>
                        hoặc để lại số điện thoại để được Chuyên gia tư vấn
                        chuyên sâu về tình trạng mất ngủ bạn đang gặp phải.
                      </p>
                      <form
                        action="/#wpcf7-f15-o1"
                        method="post"
                        class="wpcf7-form init"
                        novalidate="novalidate"
                        data-status="init"
                      >
                        <div class="w-70 contact-left">
                          <span class="wpcf7-form-control-wrap sdt"
                            ><input
                              type="number"
                              name="sdt"
                              value=""
                              min="99999999"
                              max="9999999999"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Số điện thoại"
                              v-model="formRegisterIntro.PhoneNumberIntro"
                          /></span>
                        </div>
                        <div
                          class="btn-advice-footer"
                          @click="createWebRegisterIntro"
                        >
                          <span role="status" aria-hidden="true"></span>
                          Đăng ký tư vấn
                        </div>
                        <p
                          v-if="isShowWarning"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentWarning }}
                        </p>
                        <p
                          v-if="isShowSuccess"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentSuccess }}
                        </p>
                      </form>
                    </div>
                  </div>
                  <p>
                    SIX.am đã và đang chăm sóc giấc ngủ cho hàng nghìn khách
                    hàng trên cả nước, cùng hàng nghìn phản hồi tích cực. Chuyên
                    gia khuyên bạn sử dụng SIX.am theo 2 cách sau:
                  </p>
                  <ul>
                    <li style="list-style: none">
                      ✅ Cách dùng ngắn hạn: Tạo giấc ngủ sinh lý tự nhiên, giúp
                      ngủ ngon, sâu giấc. Khi thức giấc thoải mái, không mệt
                      mỏi, an toàn, không gây phụ thuộc.
                    </li>
                    <li style="list-style: none">
                      ✅ Cách dùng dài hạn (2-3 tháng): Tái thiết lập thói quen
                      ngủ sinh lý đúng giờ. Dùng tốt nhất theo liệu trình giảm
                      dần.
                    </li>
                  </ul>
                  <p class="bm-heading">Công ty sản xuất uy tín</p>
                  <p>
                    SIX.am là sản phẩm của
                    <b>công ty CP Dược phẩm CPC1 Hà Nội</b> – là công ty dược
                    phẩm đầu tiên tại miền Bắc đạt Chứng nhận Doanh nghiệp Khoa
                    học Công nghệ. Nhà máy của công ty CPC1 Hà Nội rộng 3 héc ta
                    cùng nhiều dây chuyền hiện đại được nhập khẩu trực tiếp từ
                    Châu Âu, đạt đủ tiêu chuẩn GMP, WHO...
                  </p>
                  <ul>
                    <li>
                      Địa chỉ: Cụm Công nghiệp Hà Bình Phương, xã Văn Bình,
                      huyện Thường Tín, TP.Hà Nội
                    </li>
                    <li>Điện thoại: 0363.166.357</li>
                    <li>Website: www.cpc1hn.vn</li>
                  </ul>
                  <img src="../../../assets/images/cpc1hn.jpg" />
                  <p class="sub-img">Công ty CP Dược phẩm CPC1 Hà Nội</p>
                  <p class="bm-heading">
                    Báo Tiền Phong và Khoa học đời sống cùng nghệ sĩ giới thiệu
                    về SIX.am và hoạt chất Rotundin Sulfat
                  </p>
                  <img
                    src="../../../assets/images/khds.jpg"
                    title="SIX.am trên báo Khoa học và Đời sống"
                    alt="Ảnh chụp bài viết trên báo Khoa học và Đời Sống"
                  />
                  <p class="sub-img">
                    Ảnh chụp bài viết trên báo Khoa học và Đời Sống
                  </p>
                  <img
                    src="../../../assets/images/tienphong.jpg"
                    title="SIX.am trên báo Tiền Phong"
                    alt="Ảnh chụp bài viết trên báo Tiền Phong"
                  />
                  <p class="sub-img">Ảnh chụp bài viết trên báo Tiền Phong</p>
                  <div class="auto-resizable-iframe">
                    <div>
                      <iframe
                        style="float: left"
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/21v4M8XtC8c"
                        title="Cách chữa mất ngủ mãn tính"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <p class="sub-img" style="margin-top: 5px">
                    Nghệ sĩ ưu tú Thế Bình chia sẻ về quá trình tìm lại giấc ngủ
                    ngon an toàn cùng SIX.am
                  </p>
                  <div class="clearfix"></div>
                  <div class="auto-resizable-iframe">
                    <div>
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/aekjQD-chJU"
                        title="Mất ngủ uống thuốc gì"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <p class="sub-img" style="margin-top: 5px">
                    Nghệ sĩ ưu tú Hoàng Lan chia sẻ về nỗi đau mang tên "mất
                    ngủ"
                  </p>
                  <div class="clearfix"></div>
                  <p class="bm-heading">
                    SIX.am đã có mặt tại hơn 1000 điểm bán Nhà thuốc lớn và uy
                    tín trên toàn quốc, và được xuất khẩu tới 6 quốc gia.
                  </p>
                  <p>
                    Không chỉ chăm sóc giấc ngủ cho người Việt, tính đến quý 1
                    năm 2021, SIX.am đã được xuất khẩu tới 6 quốc gia ngoài biên
                    giới Việt Nam. Điều này không chỉ khẳng định chất lượng và
                    sự hiệu quả của SIX.am, mà còn củng cố niềm tin của khách
                    hàng khi sử dụng sản phẩm. Đặc biệt, để tiết kiệm thời gian
                    và chi phí vận chuyển, quý Khách hàng đã có thể mua SIX.am
                    ngay tại các nhà thuốc gần bạn.
                  </p>
                  <div class="bm-nhathuoc">
                    <div id="bm-banner-button" class="bm-banner-button">
                      <div class="button-wrap">
                        <a
                          target="_blank"
                          href="https://bacsytructuyen.vn/diem-ban"
                        >
                          <p style="text-align: center">
                            Tìm điểm bán SIX.am gần bạn
                          </p>
                        </a>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="contact-box">
                    <div class="w-100 box-wrap">
                      <p>
                        <i class="fas fa-phone"></i> Gọi ngay Hotline
                        <a href="tel:0363166357">0363.166.357</a>
                        hoặc để lại số điện thoại để Chuyên gia tư vấn liệu
                        trình sử dụng phù hợp và hướng dẫn mua hàng tại các nhà
                        thuốc gần nhất
                      </p>
                      <form
                        action="/#wpcf7-f15-o1"
                        method="post"
                        class="wpcf7-form init"
                        novalidate="novalidate"
                        data-status="init"
                      >
                        <div class="w-70 contact-left">
                          <span class="wpcf7-form-control-wrap sdt"
                            ><input
                              type="number"
                              name="sdt"
                              value=""
                              min="99999999"
                              max="9999999999"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Số điện thoại"
                              v-model="formRegisterIntro.PhoneNumberIntro"
                          /></span>
                        </div>
                        <div
                          class="btn-advice-footer"
                          @click="createWebRegisterIntro"
                        >
                          <span role="status" aria-hidden="true"></span>
                          Đăng ký tư vấn
                        </div>
                        <p
                          v-if="isShowWarning"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentWarning }}
                        </p>
                        <p
                          v-if="isShowSuccess"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentSuccess }}
                        </p>
                      </form>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-form bm-dathang">
                    <p class="form-title">Đặt mua SIX.am</p>
                    <p class="form-info">- Giá bán: 379.000 đồng/hộp 20 ống</p>
                    <p class="form-info">
                      - Sử dụng 1-2 ống/tối trước khi đi ngủ 30-60 phút
                    </p>
                    <p class="form-info">- Rẻ hơn khi mua theo liệu trình:</p>
                    <ul>
                      <li>
                        Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                        300.000 đồng + Phí vận chuyển
                      </li>
                      <li>
                        Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000 đồng
                        (miễn phí ship)
                      </li>
                      <li>
                        Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000 đồng
                        => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1
                        combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu
                        chân răng (miễn phí ship)
                      </li>
                    </ul>
                    <form
                      action="/#wpcf7-f17-o4"
                      method="post"
                      class="wpcf7-form init"
                      novalidate="novalidate"
                      data-status="init"
                    >
                      <div class="w-100">
                        <span class="wpcf7-form-control-wrap hoten"
                          ><input
                            type="text"
                            name="hoten"
                            value=""
                            size="40"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Họ và tên"
                            v-model="formRegister.FullName"
                        /></span>
                      </div>
                      <div class="w-50 dathang-left itemNumber">
                        <span class="wpcf7-form-control-wrap sdt"
                          ><input
                            type="number"
                            name="sdt"
                            value=""
                            min="99999999"
                            max="9999999999"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Số điện thoại"
                            v-model="formRegister.PhoneNumber"
                        /></span>
                      </div>
                      <div class="w-50 itemSolusion">
                        <span class="wpcf7-form-control-wrap lieutrinh"
                          ><select
                            name="lieutrinh"
                            aria-required="true"
                            aria-invalid="false"
                            @change="onChange($event)"
                            v-model="selecteded"
                          >
                            <option value="" disabled selected>
                              Chọn liệu trình
                            </option>
                            <option
                              value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000 đồng (miễn phí ship)"
                            >
                              Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                              379.000 đồng (miễn phí ship)
                            </option>
                            <option
                              value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000
                              đồng (miễn phí ship)"
                            >
                              Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000
                              đồng (miễn phí ship)
                            </option>
                            <option
                              value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.446.000
                              đồng (miễn phí ship)"
                            >
                              Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.446.000
                              đồng (miễn phí ship)
                            </option>
                          </select></span
                        >
                      </div>
                      <div class="w-100">
                        <span class="wpcf7-form-control-wrap diachi">
                          <textarea
                            name="diachi"
                            cols="40"
                            rows="10"
                            aria-required="true"
                            aria-invalid="false"
                            placeholder="Địa chỉ nhận hàng"
                            v-model="formRegister.Note"
                          ></textarea>
                        </span>
                      </div>
                      <p
                        v-if="isShowWarning"
                        class="mt-2"
                        style="color: #fff; margin-bottom: 16px"
                      >
                        {{ contentWarning }}
                      </p>
                      <p
                        v-if="isShowSuccess"
                        class="mt-2"
                        style="color: #fff; margin-bottom: 16px"
                      >
                        {{ contentSuccess }}
                      </p>
                      <div @click="createWebRegister" class="btn-summit">
                        <span
                          role="status"
                          aria-hidden="true"
                          v-if="loadding"
                        ></span>
                        Đặt hàng
                      </div>
                    </form>
                  </div>
                  <div class="bm-author">
                    <p>Tác giả: Chuyên gia tư vấn - 20/3/2021</p>
                  </div>
                </div>
              </div>
              <div class="bm-cmt">
                <p class="cmt-heading">
                  <span> Bình luận nổi bật </span>
                </p>
                <div class="bm-single-cmt">
                  <img class="bm-ava" src="../../../assets/images/fb-1.jpg" />
                  <div class="right">
                    <p class="cmt-name">Bui Dung</p>
                    <p class="cmt-para">
                      Đúng là sưc khoe quan trong lam, cứ mất ngủ 1 hôm thôi là
                      nguoi cư như mât hôn
                    </p>
                    <p class="cmt-date">
                      <span class="day">Ngày đăng: 2 giờ trước</span>
                      <span class="like">3</span>
                    </p>
                  </div>
                </div>
                <div class="bm-single-cmt">
                  <img class="bm-ava" src="~@/assets/images/fb-2.jpg" />
                  <div class="right">
                    <p class="cmt-name">Khanh Nguyễn</p>
                    <p class="cmt-para">
                      <b>Thúy Diễm</b> Trước cháu mua sp mất ngủ cho mẹ ở đây
                      này cô. Dùng hiệu quả cô ạ
                    </p>
                    <p class="cmt-date">
                      <span class="day">Ngày đăng: 5 giờ trước</span>
                      <span class="like">6</span>
                    </p>
                  </div>
                </div>
                <div class="bm-single-cmt">
                  <img class="bm-ava" src="../../../assets/images/fb-3.jpg" />
                  <div class="right">
                    <p class="cmt-name">Mẹ Trang Mun</p>
                    <p class="cmt-para">
                      Uong bao lau thì khoi? Cho 1 lieu trình
                    </p>
                    <p class="cmt-date">
                      <span class="day">Ngày đăng: 11 giờ trước</span>
                      <span class="like">1</span>
                    </p>
                  </div>
                </div>
                <div class="bm-single-cmt">
                  <img class="bm-ava" src="../../../assets/images/fb-4.jpg" />
                  <div class="right">
                    <p class="cmt-name">Nguyễn Thị Yến</p>
                    <p class="cmt-para">
                      <b>Hương Trần</b> <b>Khôi Minh</b> tớ dùng đã thấy hiệu
                      quả 2 người tham khão mà mua nè...
                    </p>
                    <p class="cmt-date">
                      <span class="day">Ngày đăng: 1 ngày trước</span>
                      <span class="like">8</span>
                    </p>
                  </div>
                </div>
                <div class="bm-single-cmt">
                  <img class="bm-ava" src="../../../assets/images/fb-5.jpg" />
                  <div class="right">
                    <p class="cmt-name">Hong Van</p>
                    <p class="cmt-para">Gui chi 2 hop theo dia chi cu nhe!</p>
                    <p class="cmt-date">
                      <span class="day">Ngày đăng: 1 ngày trước</span>
                      <span class="like">14</span>
                    </p>
                  </div>
                </div>
                <div class="bm-single-cmt">
                  <img class="bm-ava" src="../../../assets/images/fb-6.jpg" />
                  <div class="right">
                    <p class="cmt-name">Ngô Hồng</p>
                    <p class="cmt-para">
                      Uống deu thì rất tot nha moi nguoi ơi. Minh đa ngủ đc 4-5
                      tiếng một tối rồi nhen.
                    </p>
                    <p class="cmt-date">
                      <span class="day">Ngày đăng: 28/03/21</span>
                      <span class="like">21</span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="bm-cmt bm-relate">
                <p class="cmt-heading">
                  <span> Bài viết liên quan </span>
                </p>
                <ul v-for="(item, index) in tableDateFilter" :key="index">
                  <li>
                    <a @click="btnPushInfor(item)" style="cursor: pointer">
                      {{ item.WebTitle }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
  <!-- #site-content -->
</template>

<script>
import { mapMutations } from "vuex";
import Cookies from "js-cookie";
import { CreateWebRegister, GetWebPostsLst } from "@/api/place";
export default {
  data() {
    return {
      selecteded: "",
      disciption: "",
      tableData: [],
      tableDateFilter: [],
      counter: "",
      isShowWarning: false,
      isShowSuccess: false,
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      contentWarning: "Vui lòng điền đủ thông tin!",
      loadding: false,
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
      formRegisterIntro: {
        PhoneNumberIntro: "",
        FullNameIntro: "",
        DesctiptionIntro: "",
        NoteIntro: "",
      },
    };
  },
  created() {
    this.getWebPostsLst();
  },
  methods: {
    //...mapMutations(["updatePostID"]),
    btnPushInfor(item) {
      // this.updatePostID(item.RowID);
      Cookies.set("value", item.RowID);
      function convertWebTitlle(str) {
        str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
        str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
        str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
        str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
        str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
        str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
        str = str.replace(/đ/g, "d");
        str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
        str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
        str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
        str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
        str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
        str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
        str = str.replace(/Đ/g, "D");
        str = str.replace(
          /!|@|%|\^|\*|\(|\)|\+|\?|\/|,|\.|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
          " "
        );
        str = str.replace(/ /g, "-");
        return str;
      }
      this.titleWeb = convertWebTitlle(item.WebTitle);
      this.$router.push(this.titleWeb);
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "SIX.am",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption.length,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
    getWebPostsLst() {
      GetWebPostsLst({ ProductID: "Six.am" }).then((res) => {
        this.tableData = res.data.WebPostsLst.filter((p) => p.Status == 1).map(
          (item, index) => {
            return {
              ...item,
              LinkImage:
                "https://icpc1hn.work/DTP/File/DownloadImageWebPosts?UserName=&Token=&RowID=" +
                item.RowID,
              Key: index + 1,
            };
          }
        );
        for (let i = 0; i < this.tableData.length; i++) {
          this.rowIndex =
            this.tableData[Math.floor(Math.random() * this.tableData.length)];
          if (this.rowIndex.RowID != this.currentRowIndex) {
            this.tableDateFilter.push(this.rowIndex);
            this.counter++;
          }
          if (this.counter === 3) break;
        }
      });
    },
    createWebRegisterIntro() {
      if (this.formRegisterIntro.PhoneNumberIntro.length == 0) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegisterIntro.PhoneNumberIntro[0] != 0 ||
        this.formRegisterIntro.PhoneNumberIntro.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.formRegisterIntro.FullNameIntro = "";
      this.formRegisterIntro.NoteIntro = "";
      this.formRegisterIntro.DesctiptionIntro = "";
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegisterIntro.PhoneNumberIntro,
          ProductID: "SIX.am",
          TypeDoc: "TV",
          FullName: this.formRegisterIntro.FullNameIntro,
          Note: this.formRegisterIntro.NoteIntro,
          Desctiption: this.formRegisterIntro.DesctiptionIntro,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.like {
  background-image: url("../../../assets/images/like.png");

  background-repeat: repeat-y;
}
.mt-2 {
  text-align: center !important;
}
.btn-summit {
  text-align: center;
  font-family: "Quicksand Regular" !important;
  font-size: 17px !important;
  padding: 10px !important;
}
.btn-advice-footer {
  font-size: 18px !important;
}
a {
  color: #337ab7;
}
.bm-cmt p {
  margin-bottom: 10px !important;
}
@media (min-width: 770px) {
  .itemNumber {
    width: 30%;
  }
  .itemSolusion {
    width: 70%;
  }
}
</style>
