<template>
  <div>
    <div id="items-direction" style="position: absolute; top: 0"></div>
    <div id="baomoi-content" class="baomoi-content bm-home">
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-md hidden-lg"
        src="../../../assets/images/banner-mobile.png"
      />
      <img
        style="margin: 0 auto"
        class="bm-banner-img hidden-sm hidden-xs"
        src="../../../assets/images/bannerv3.png"
      />

      <div class="container">
        <div class="row">
          <div class="single-post-wrap">
            <div class="single-content">
              <div class="bm-main-content">
                <p class="bm-banner-slogan">Mất ngủ - SIX.am là đủ</p>

                <div class="col-md-6 col-xs-12">
                  <img
                    class="bm-button-km"
                    src="../../../assets/images/image-price-v2.png"
                  />
                </div>
                <div class="col-md-6 col-xs-12">
                  <img
                    class="bm-button-km"
                    src="../../../assets/images/hop6am.png"
                  />
                </div>
                <div class="bm-banner-button">
                  <div class="button-wrap">
                    <p>Đã qua lâm sàng hoạt chất</p>
                  </div>
                </div>
                <section id="know">
                  <div class="container">
                    <div class="row">
                      <div class="know-in">
                        <div class="col-md-3">
                          <div class="item_l">
                            <div class="tb">
                              <div class="tb_cell">
                                <img
                                  src="../../../assets/images/ban-co-biet.png"
                                />
                                <div class="text-justify">
                                  Như thế nào được gọi là mất ngủ? Như thế nào
                                  là chất lượng giấc ngủ kém?
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="item_r">
                            <div class="item tb" style="min-width: 100%">
                              <div class="tb_l">
                                <img
                                  src="../../../assets/images/icon-reason-1.png"
                                />
                              </div>
                              <div class="tb_r text-justify">
                                <b>Khó vào giấc ngủ</b>, muốn ngủ mà nằm xuống
                                không ngủ được. <b>Giấc ngủ chập chờn</b>, hay
                                tỉnh giấc.
                              </div>
                            </div>
                            <div class="item tb mt-10" style="min-width: 100%">
                              <div class="tb_l">
                                <img
                                  src="../../../assets/images/icon-reason-2.png"
                                />
                              </div>
                              <div class="tb_r text-justify">
                                <b>Mất ngủ do căng thẳng, stress</b>. Trằn trọc
                                lo âu mỗi khi đi ngủ. Giấc ngủ quá ngắn, tỉnh
                                giấc giữa đêm <b>không ngủ lại được</b>.
                              </div>
                            </div>
                            <div class="item tb mt-10" style="min-width: 100%">
                              <div class="tb_l">
                                <img
                                  src="../../../assets/images/icon-reason-3.png"
                                />
                              </div>
                              <div class="tb_r text-justify">
                                <b>Rối loạn thần kinh thực vật</b>, rối loạn
                                giấc ngủ.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>

                      <div
                        class="col-xs-12 khuyen-cao"
                        style="margin-top: 30px"
                      >
                        <div class="contain_kc">
                          <div class="kc_title pos-r">
                            <img
                              class="pos-a"
                              src="../../../assets/images/icon-khuyen-cao-large.svg"
                            />
                            <b>Khuyến cáo:</b> Đừng để tình trạng mất ngủ trầm
                            trọng rồi mới quan tâm đến nó.
                          </div>
                          <ul class="kc_content fz-16">
                            <li class="bullet_warning">
                              Mất ngủ là một bệnh lý nguy hiểm.
                              <b>Mất ngủ mãn tính</b> cực kỳ khó chữa trị dứt
                              điểm
                            </li>
                            <li class="bullet_warning">
                              Sử dụng các loại thuốc ngủ tùy tiện có thể gây ra
                              các tác dụng phụ như đau đầu, mệt mỏi và phụ thuộc
                            </li>
                            <li class="bullet_check_alt">
                              <b
                                >Nên chọn sản phẩm giúp ngủ ngon an toàn, không
                                gây đau đầu hay mệt mỏi, không có tác dụng phụ,
                                nguồn gốc tự nhiên</b
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <section id="intro">
                  <div class="container">
                    <div class="row">
                      <div class="title-p">
                        Nếu ngủ dưới 5 tiếng mỗi ngày, cơ thể sẽ có nguy cơ gì?
                      </div>
                      <div class="button-quote">
                        Mất ngủ mãn tính là nguyên nhân chính làm tăng nguy
                        cơ<br class="hidden-xs" />
                        mắc các bệnh lý nguy hiểm, đặc biệt ở người cao tuổi.
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="full_w bg_line outside">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="item">
                <div class="block_l">
                  <img src="../../../assets/images/up-1.png" />
                </div>
                <div class="item_content">
                  Tăng 2,5 lần nguy cơ <br class="hidden-xs hidden-sm" />
                  mắc bệnh tiểu đường
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="item">
                <div class="block_l">
                  <img src="../../../assets/images/up-2.png" />
                </div>
                <div class="item_content">
                  Tăng 45% nguy cơ <br class="hidden-xs hidden-sm" />trụy tim,
                  đau tim
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="item">
                <div class="block_l">
                  <img src="../../../assets/images/up-3.png" />
                </div>
                <div class="item_content">
                  Tăng 12% nguy cơ <br class="hidden-xs hidden-sm" />tử vong do
                  đột quỵ
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="single-post-wrap">
            <div class="single-content">
              <div class="content-wrap">
                <div class="bm-main-content">
                  <div class="contact-box" style="margin-top: 30px">
                    <div class="w-100 box-wrap">
                      <p>
                        <i class="fas fa-phone"></i> Gọi ngay Hotline
                        <a href="tel:0363166357">0363.166.357</a>
                        hoặc để lại số điện thoại để được Chuyên gia tư vấn
                        chuyên sâu về tình trạng mất ngủ bạn đang gặp phải.
                      </p>
                      <form
                        action="/#wpcf7-f15-o1"
                        method="post"
                        class="wpcf7-form init"
                        novalidate="novalidate"
                        data-status="init"
                      >
                        <div class="w-70 contact-left">
                          <span class="wpcf7-form-control-wrap sdt"
                            ><input
                              type="number"
                              name="sdt"
                              value=""
                              min="99999999"
                              max="9999999999"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Số điện thoại"
                              v-model="formRegisterIntro.PhoneNumberIntro"
                          /></span>
                        </div>
                        <div
                          class="btn-advice-footer"
                          @click="createWebRegisterIntro"
                        >
                          <span role="status" aria-hidden="true"></span>
                          Đăng ký tư vấn
                        </div>
                        <p
                          v-if="isShowWarning"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentWarning }}
                        </p>
                        <p
                          v-if="isShowSuccess"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentSuccess }}
                        </p>
                      </form>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-nghesi">
                    <p>Tôi chọn SIX.am!</p>
                    <div class="clearfix"></div>
                    <div class="w-50">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/63KoB9fp7Hg"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div class="col-md-6">
                      <div class="block-dashed">
                        "Bắt đầu có tuổi, cộng với tính chất công việc, tôi bị
                        mất ngủ trầm trọng! Mỗi đêm chỉ ngủ được 1-2 tiếng. Sức
                        khỏe và thần thái giảm đi thấy rõ. Thật may, tôi đã tìm
                        được sản phẩm SIX.am. Chỉ sau liệu trình 2 tháng, giấc
                        ngủ no tròn đã trở lại với tôi. Cảm ơn SIX.am đã giúp
                        tôi "Tạm biệt" mất ngủ!" - NSƯT Thế Bình chia sẻ.
                      </div>
                    </div>
                  </div>
                  <img
                    class="tp-img"
                    src="../../../assets/images/bangsangche.jpg"
                    title="SIX.am là sản phẩm duy nhất trên thị trường có hoạt chất Rotundin Sulfat"
                    alt="SIX.am là sản phẩm duy nhất trên thị trường có hoạt chất Rotundin Sulfat"
                  />
                  <div class="bm-cdung">
                    <p>
                      SIX.am dựa trên bằng Độc quyền sáng chế cấp quốc gia hoạt
                      chất Rotundin Sulfat của PGS.TS Nguyễn Minh Chính - Nguyên
                      Giám đốc Trung tâm nghiên cứu dược Học viện Quân Y.
                      Rotundin Sulfat đã được kiểm nghiệm lâm sàng tại Quân Y
                      Viện 103 trên các BN mất ngủ mãn tính với kết quả:
                    </p>
                    <div class="cdung-wrap">
                      <div class="col-md-6 col-xs-12 single-cd">
                        <div class="col-xs-3 col-md-4">
                          <img src="../../../assets/images/cd-1.png" />
                        </div>
                        <div class="col-xs-9 col-md-8 cdung-info">
                          Vào giấc ngủ nhanh (từ 30-60 phút)
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12 single-cd">
                        <div class="col-xs-3 col-md-4">
                          <img src="../../../assets/images/cd-2.png" />
                        </div>
                        <div class="col-xs-9 col-md-8 cdung-info">
                          Giấc ngủ sâu, liền mạch, không mộng mị
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="col-md-6 col-xs-12 single-cd">
                        <div class="col-xs-3 col-md-4">
                          <img src="../../../assets/images/cd-3.png" />
                        </div>
                        <div class="col-xs-9 col-md-8 cdung-info">
                          Thức giấc khoan khoái, không gây đau đầu, mệt mỏi
                        </div>
                      </div>
                      <div class="col-md-6 col-xs-12 single-cd">
                        <div class="col-xs-3 col-md-4">
                          <img src="../../../assets/images/cd-4.png" />
                        </div>
                        <div class="col-xs-9 col-md-8 cdung-info">
                          An toàn, không tác dụng phụ, không gây phụ thuộc
                        </div>
                      </div>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-banner-button">
                    <div class="button-wrap">
                      <a
                        target="_blank"
                        href="http://www.benhvien103.vn/nghien-cuu-tac-dung-gay-ngu-cua-rotundin-sulphat-duong-uong/"
                      >
                        <p style="text-align: center">Xem kết quả lâm sàng</p>
                      </a>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div id="">
                    <p class="normal-title">Ai nên sử dụng <b>SIX.am</b>?</p>
                    <div class="dt_list col-xs-12">
                      <div class="col-md-6">
                        <div
                          class="item-introduce"
                          style="
                            background: #122b4d;
                            border-radius: 10px;
                            font-size: 18px;
                            color: white;
                            font-family: Mulish !important;
                            margin-bottom: 30px;
                            text-align: left;
                          "
                        >
                          <img
                            class="item-img-introduce"
                            style="
                              width: auto;
                              vertical-align: middle;
                              display: inline-block;
                              margin: 0;
                              max-width: 100%;
                            "
                            src="../../../assets/images/doi-tuong-su-dung-1.png"
                            alt="doi-tuong-su-dung"
                          />
                          <b
                            ><span
                              style="
                                font-family: Mulish Bold !important;
                                font-size: 15px;
                                letter-spacing: normal;
                              "
                              >Mất ngủ mãn tính, nhiều đêm thức trắng</span
                            ></b
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          class="item-introduce"
                          style="
                            background: #122b4d;
                            border-radius: 10px;
                            font-size: 18px;
                            color: white;
                            font-family: Mulish !important;
                            margin-bottom: 30px;
                            text-align: left;
                          "
                        >
                          <img
                            class="item-img-introduce"
                            style="
                              width: auto;
                              vertical-align: middle;
                              display: inline-block;
                              margin: 0;
                              max-width: 100%;
                            "
                            src="../../../assets/images/doi-tuong-su-dung-2.png"
                            alt="doi-tuong-su-dung"
                          />
                          <b
                            ><span
                              style="
                                font-family: Mulish Bold !important;
                                font-size: 15px;
                                letter-spacing: normal;
                              "
                              >Khó vào giác, ngủ chập chờn, không sâu</span
                            ></b
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          class="item-introduce"
                          style="
                            background: #122b4d;
                            border-radius: 10px;
                            font-size: 18px;
                            color: white;
                            font-family: Mulish !important;
                            margin-bottom: 30px;
                            text-align: left;
                          "
                        >
                          <img
                            class="item-img-introduce"
                            style="
                              width: auto;
                              vertical-align: middle;
                              display: inline-block;
                              margin: 0;
                              max-width: 100%;
                            "
                            src="../../../assets/images/doi-tuong-su-dung-3.png"
                            alt="doi-tuong-su-dung"
                          />
                          <b
                            ><span
                              style="
                                font-family: Mulish Bold !important;
                                font-size: 15px;
                                letter-spacing: normal;
                              "
                              >Mất ngủ do căng thẳng, stress, trầm cảm</span
                            ></b
                          >
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div
                          class="item-introduce"
                          style="
                            background: #122b4d;
                            border-radius: 10px;
                            font-size: 18px;
                            color: white;
                            font-family: Mulish !important;
                            margin-bottom: 30px;
                            text-align: left;
                          "
                        >
                          <img
                            class="item-img-introduce"
                            style="
                              width: auto;
                              vertical-align: middle;
                              display: inline-block;
                              margin: 0;
                              max-width: 100%;
                            "
                            src="../../../assets/images/doi-tuong-su-dung-4.png"
                            alt="doi-tuong-su-dung"
                          />
                          <b
                            ><span
                              style="
                                font-family: Mulish Bold !important;
                                font-size: 15px;
                                letter-spacing: normal;
                              "
                              >Rối loạn giấc ngủ, rối loạn thần kinh thực
                              vật</span
                            ></b
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-lieutrinh">
                    <div class="block-dashed">
                      SIX.am có tác dụng: Hỗ trợ an thần, hỗ trợ tạo giấc ngủ
                      ngon, ngủ sâu giấc cho những người bị mất ngủ, khó ngủ,
                      đặc biệt ở người cao tuổi. Qua nghiên cứu lâm sàng hoạt
                      chất Rotundin Sulfat tại Quân Y Viện 103, cùng sự nghiên
                      cứu chuyên sâu, Chuyên gia tư vấn bạn 2 cách dùng (liệu
                      trình) sử dụng SIX.am hiệu quả như sau:
                    </div>
                    <img
                      src="../../../assets/images/sudung.jpg"
                      title="Ai đang mất ngủ đọc ngay bí quyết hỗ trợ ngủ ngon mỗi đêm"
                      alt="Để lại sđt, Chuyên gia tư vấn cách dùng và liệu trình sử dụng SIX.am phù hợp cho bạn"
                    />
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-khacbiet">
                    <p class="normal-title">SIX.am có gì khác biệt</p>
                    <div class="kb-a-wrap">
                      <div class="col-md-6 kb-a">
                        <img src="../../../assets/images/kb-1.jpg" />
                        <p>
                          Sinh khả dụng nhanh do sản phẩm ở dạng nước. Chỉ cần
                          sử dụng trước khi ngủ 30 phút
                        </p>
                      </div>
                      <div class="col-md-6 kb-a">
                        <img src="../../../assets/images/kb-2.jpg" />
                        <p>
                          Cơ chế tạo giấc ngủ sinh lý tự nhiên, thức dậy không
                          mệt mỏi.
                        </p>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="kb-b-wrap">
                      <div class="col-xs-4 kb-b">
                        <img src="../../../assets/images/not-1.png" />
                        <p>Không phải thuốc tây</p>
                      </div>
                      <div class="col-xs-4 kb-b">
                        <img src="../../../assets/images/not-2.png" />
                        <p>Không tác dụng phụ</p>
                      </div>
                      <div class="col-xs-4 kb-b">
                        <img src="../../../assets/images/not-3.png" />
                        <p>Không đường</p>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="centered col-xs-12 bm-pro">
                    <div class="block-dashed col-xs-12">
                      <div class="col-md-1 col-xs-12 idea">
                        <img src="../../../assets/images/icon-bat-mi.svg" />
                      </div>
                      <div
                        class="col-md-11 col-xs-12 idea-text"
                        style="margin-bottom: 20px"
                      >
                        <b>
                          Góc nhìn chuyên gia: Từ củ bình vôi tới hoạt chất mới
                          trong Dược điển Việt Nam.
                        </b>
                        <br />
                        Không dừng lại trong việc sử dụng Rotundin ở dạng base,
                        sau 16 năm nghiên cứu, PGS.TS Nguyễn Minh Chính và đồng
                        sự đã điều chế thành công hoạt chất Rotundin Sulfat, tan
                        hoàn toàn trong nước và sinh khả dụng cao hơn rất nhiều
                        so với Rotundin base.
                      </div>
                      <div class="clearfix"></div>
                      <div class="iframe-container">
                        <iframe
                          src="https://www.youtube.com/embed/vk3kGZiLI4E"
                          frameborder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-form bm-dathang">
                    <p class="form-title">Đặt mua SIX.am</p>
                    <p class="form-info">- Giá bán: 379.000 đồng/hộp 20 ống</p>
                    <p class="form-info">
                      - Sử dụng 1-2 ống/tối trước khi đi ngủ 30-60 phút
                    </p>
                    <p class="form-info">
                      - Rẻ hơn và miễn phí vận chuyển khi mua theo liệu trình:
                    </p>
                    <ul>
                      <li>
                        Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                        379.000 đồng (miễn phí ship)
                      </li>
                      <li>
                        Liệu trình 1 tháng: 2 hộp SIX.am có giá có giá 758.000
                        đồng => Được giảm 70.000 đồng còn 688.000 đồng (miễn phí
                        ship)
                      </li>
                      <li>
                        Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000 đồng
                        => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1
                        combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu
                        chân răng (miễn phí ship)
                      </li>
                    </ul>
                    <div
                      role="form"
                      class="wpcf7"
                      id="wpcf7-f17-o4"
                      lang="en-US"
                      dir="ltr"
                    >
                      <div class="screen-reader-response">
                        <ul></ul>
                      </div>
                      <form
                        action="/#wpcf7-f17-o4"
                        method="post"
                        class="wpcf7-form init"
                        novalidate="novalidate"
                        data-status="init"
                      >
                        <div class="w-100">
                          <span class="wpcf7-form-control-wrap hoten"
                            ><input
                              type="text"
                              name="hoten"
                              value=""
                              size="40"
                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Họ và tên"
                              v-model="formRegister.FullName"
                          /></span>
                        </div>
                        <div class="w-50 dathang-left">
                          <span class="wpcf7-form-control-wrap sdt"
                            ><input
                              type="number"
                              name="sdt"
                              value=""
                              class="wpcf7-form-control wpcf7-number wpcf7-validates-as-required wpcf7-validates-as-number"
                              min="99999999"
                              max="9999999999"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Số điện thoại"
                              v-model="formRegister.PhoneNumber"
                          /></span>
                        </div>
                        <div class="w-50">
                          <span class="wpcf7-form-control-wrap lieutrinh"
                            ><select
                              name="lieutrinh"
                              aria-required="true"
                              aria-invalid="false"
                              @change="onChange($event)"
                              v-model="selecteded"
                            >
                              <option value="" disabled selected>
                                Chọn liệu trình
                              </option>
                              <option
                                value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000 đồng (miễn phí ship)"
                              >
                                Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có
                                giá 379.000 đồng (miễn phí ship)
                              </option>
                              <option
                                value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 758.000 đồng => Được giảm 70.000 đồng còn 688.000 đồng (miễn phí ship)"
                              >
                                Liệu trình 1 tháng: 2 hộp SIX.am có giá 758.000
                                đồng => Được giảm 70.000 đồng còn 688.000 đồng
                                (miễn phí ship)
                              </option>
                              <option
                                value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000 đồng => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1 combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu chân răng (miễn phí ship)"
                              >
                                Liệu trình 2 tháng: 4 hộp SIX.am có giá
                                1.516.000 đồng => Được giảm 70.000 đồng còn
                                1.446.000 đồng + tặng 1 combo nước súc miệng và
                                kem đánh răng ngăn ngừa chảy máu chân răng (miễn
                                phí ship)
                              </option>
                            </select></span
                          >
                        </div>
                        <div class="w-100">
                          <span class="wpcf7-form-control-wrap diachi">
                            <textarea
                              name="diachi"
                              cols="40"
                              rows="10"
                              class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Địa chỉ nhận hàng"
                              v-model="formRegister.Note"
                            ></textarea>
                          </span>
                        </div>
                        <p
                          v-if="isShowWarning"
                          class="mt-2"
                          style="color: #fff; margin-bottom: 16px"
                        >
                          {{ contentWarning }}
                        </p>
                        <p
                          v-if="isShowSuccess"
                          class="mt-2"
                          style="color: #fff; margin-bottom: 16px"
                        >
                          {{ contentSuccess }}
                        </p>
                        <div @click="createWebRegister" class="btn-summit">
                          <span
                            role="status"
                            aria-hidden="true"
                            v-if="loadding"
                          ></span>
                          Đặt hàng
                        </div>
                      </form>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div id="trust" class="bm-tincay">
                    <p class="normal-title">SIX.am có đáng tin cậy không?</p>
                    <p>
                      SIX.am đã được bộ Y Tế cấp phép, dựa trên bằng độc quyền
                      sáng chế cấp quốc gia hoạt chất Rotundin Sulfate. SIX.am
                      được sản xuất trên dây chuyền sản xuất hiện đại bậc nhất
                      của Công ty CP Dược phẩm CPC1 Hà Nội - công ty Dược phẩm
                      duy nhất của miền Bắc đạt chứng nhận Doanh nghiệp Khoa học
                      và Công nghệ. Với nhà máy hiện đại rộng 30.000 mét vuông,
                      đạt tiêu chuẩn GMP - WHO, cùng dây chuyền sản xuất tự động
                      hóa và khép kín, sản phẩm của CPC1 Hà Nội mang chất lượng
                      sánh ngang với các sản phẩm của các cường quốc về Dược
                      phẩm trên thế giới.
                    </p>
                    <div class="col-md-4 col-xs-6 item">
                      <div class="img-wrap">
                        <img src="../../../assets/images/bang-sang-che.png" />
                      </div>
                      <div class="name-block">BẰNG ĐỘC QUYỀN SÁNG CHẾ</div>
                    </div>
                    <div class="col-md-4 hidden-sm hidden-xs item">
                      <div class="img-wrap">
                        <img src="../../../assets/images/gmp.png" />
                      </div>
                      <div class="name-block">CHỨNG NHẬN GMP-WHO</div>
                    </div>
                    <div class="col-md-4 col-xs-6 item">
                      <div class="img-wrap">
                        <img src="../../../assets/images/congbo.png" />
                      </div>
                      <div class="name-block">ĐƯỢC BỘ Y TẾ CẤP PHÉP</div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-baochi">
                    <p class="normal-title">Báo chí nói gì về SIX.am</p>
                    <img
                      src="../../../assets/images/khds.jpg"
                      title="SIX.am trên báo Khoa học và Đời sống"
                      alt="Ảnh chụp bài viết trên báo Khoa học và Đời Sống"
                    />
                    <p class="sub-img">
                      Ảnh chụp bài viết trên báo Khoa học và Đời Sống
                    </p>
                    <img
                      src="../../../assets/images/tienphong.jpg"
                      title="SIX.am trên báo Tiền Phong"
                      alt="Ảnh chụp bài viết trên báo Tiền Phong"
                    />
                    <p class="sub-img">Ảnh chụp bài viết trên báo Tiền Phong</p>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-feedback">
                    <p class="normal-title">Phản hồi từ khách hàng</p>
                    <div class="single-fb-wrap">
                      <div class="single-cmt-fb">
                        <div class="ava-fb">
                          <img src="../../../assets/images/ava-fb-1.png" />
                        </div>
                        <div class="cmt-fb">
                          <img
                            class="hidden-xs"
                            src="../../../assets/images/cmt-fb-1.jpg"
                          />
                          <img
                            class="hidden-lg hidden-sm hidden-md"
                            src="../../../assets/images/cmt-fb-2.jpg"
                          />
                          <div class="cmt-section">
                            <p class="fb-name">Bui Dung</p>
                            <p class="fb-txt">
                              Bị mất ngủ đã lâu, may sao tìm thấy sp sixam này
                              trên mạng. Mua về dùng đã ngủ lại được. Xin cám
                              ơn!
                            </p>
                          </div>
                          <div class="fb-date">
                            <p>
                              <span>Thích</span><span>Trả lời</span
                              ><span class="fb-time">2 giờ trước</span>
                            </p>
                          </div>
                          <div class="fb-like-count">
                            <p>23</p>
                          </div>
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="single-fb-wrap">
                        <div class="single-cmt-fb">
                          <div class="ava-fb">
                            <img src="../../../assets/images/ava-fb-2.png" />
                          </div>
                          <div class="cmt-fb">
                            <img
                              class="hidden-xs"
                              src="../../../assets/images/cmt-fb-1.jpg"
                            />
                            <img
                              class="hidden-lg hidden-sm hidden-md"
                              src="../../../assets/images/cmt-fb-2.jpg"
                            />
                            <div class="cmt-section">
                              <p class="fb-name">Nguyễn Thị Yến</p>
                              <p class="fb-txt">
                                Dùng cũng đc 3 hộp rồi. Giấc ngủ có cải thiện
                                tốt cả nhà ạ! Mong hết liệu trình là ngủ lại
                                đúng giấc như hồi xưa kkk
                              </p>
                            </div>
                            <div class="fb-date">
                              <p>
                                <span>Thích</span><span>Trả lời</span
                                ><span class="fb-time">3 giờ trước</span>
                              </p>
                            </div>
                            <div class="fb-like-count">
                              <p>16</p>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="single-fb-wrap">
                        <div class="single-cmt-fb">
                          <div class="ava-fb">
                            <img src="../../../assets/images/ava-fb-3.png" />
                          </div>
                          <div class="cmt-fb">
                            <img
                              class="hidden-xs"
                              src="../../../assets/images/cmt-fb-1.jpg"
                            />
                            <img
                              class="hidden-lg hidden-sm hidden-md"
                              src="../../../assets/images/cmt-fb-2.jpg"
                            />
                            <div class="cmt-section">
                              <p class="fb-name">Huỳnh Thanh Xuân</p>
                              <p class="fb-txt hidden-xs">
                                Trước cũng ko tin sp quảng cáo. Nhưng mất ngủ
                                thì không chịu đc. Dùng SIxam này ngủ được tốt
                                lắm, dậy sảng khoái ko bị met...
                              </p>
                              <p class="fb-txt hidden-sm hidden-md hidden-lg">
                                Trước cũng ko tin sp quảng cáo. Nhưng mất ngủ
                                thì không chịu đc. Dùng SIxam này ngủ được tốt
                                lắm...
                              </p>
                            </div>
                            <div class="fb-date">
                              <p>
                                <span>Thích</span><span>Trả lời</span
                                ><span class="fb-time">5 giờ trước</span>
                              </p>
                            </div>
                            <div class="fb-like-count">
                              <p>29</p>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="single-fb-wrap">
                        <div class="single-cmt-fb">
                          <div class="ava-fb">
                            <img src="../../../assets/images/ava-fb-4.png" />
                          </div>
                          <div class="cmt-fb">
                            <img
                              class="hidden-xs"
                              src="../../../assets/images/cmt-fb-1.jpg"
                            />
                            <img
                              class="hidden-lg hidden-sm hidden-md"
                              src="../../../assets/images/cmt-fb-2.jpg"
                            />
                            <div class="cmt-section">
                              <p class="fb-name">Tinh Tran</p>
                              <p class="fb-txt">
                                Mới uống 2 ngày nhưng có hiệu quả đấy! Bà con ai
                                uống đủ liệu trình rồi có thể phản hồi xem có
                                hết mất ngủ ko???
                              </p>
                            </div>
                            <div class="fb-date">
                              <p>
                                <span>Thích</span><span>Trả lời</span
                                ><span class="fb-time">7 giờ trước</span>
                              </p>
                            </div>
                            <div class="fb-like-count">
                              <p>14</p>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="single-fb-wrap">
                        <div class="single-cmt-fb">
                          <div class="ava-fb">
                            <img src="../../../assets/images/ava-fb-5.png" />
                          </div>
                          <div class="cmt-fb">
                            <img
                              class="hidden-xs"
                              src="../../../assets/images/cmt-fb-1.jpg"
                            />
                            <img
                              class="hidden-lg hidden-sm hidden-md"
                              src="../../../assets/images/cmt-fb-2.jpg"
                            />
                            <div class="cmt-section">
                              <p class="fb-name">Nguyễn Thị Yến</p>
                              <p class="fb-txt">
                                Sản phẩm tốt, em mất ngủ sau sinh dùng đủ loại
                                nhưng SIX.am này là có hiệu quả nhất. Dậy không
                                bị lơ mơ mệt mỏi đâu
                              </p>
                            </div>
                            <div class="fb-date">
                              <p>
                                <span>Thích</span><span>Trả lời</span
                                ><span class="fb-time">12 giờ trước</span>
                              </p>
                            </div>
                            <div class="fb-like-count">
                              <p>32</p>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                      <div class="single-fb-wrap">
                        <div class="single-cmt-fb">
                          <div class="ava-fb">
                            <img src="../../../assets/images/ava-fb-6.png" />
                          </div>
                          <div class="cmt-fb">
                            <img
                              class="hidden-xs"
                              src="../../../assets/images/cmt-fb-1.jpg"
                            />
                            <img
                              class="hidden-lg hidden-sm hidden-md"
                              src="../../../assets/images/cmt-fb-2.jpg"
                            />
                            <div class="cmt-section">
                              <p class="fb-name">Khuê Phạm Nhạc</p>
                              <p class="fb-txt">
                                T cũng hết mất ngủ rồi. Cám ơn SIX.am! T sẽ giới
                                thiệu cho bạn bè của mình! Ai dang mát ngủ nên
                                sử dụng thử.
                              </p>
                            </div>
                            <div class="fb-date">
                              <p>
                                <span>Thích</span><span>Trả lời</span
                                ><span class="fb-time">14 giờ trước</span>
                              </p>
                            </div>
                            <div class="fb-like-count">
                              <p>22</p>
                            </div>
                          </div>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-nghesi">
                    <div class="w-50">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/F8MhlOF1674"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div class="col-md-6">
                      <div class="block-dashed">
                        "Mất ngủ triền miên làm làn da của tôi xấu đi thấy rõ.
                        Những nếp nhăn, vết chân chim xuất hiện ngày một nhiều
                        trên khuôn mặt. Cộng thêm thần thái ngày càng giảm sút,
                        ai cũng nói tôi như già đi vài tuổi. Cám ơn SIX.am đã
                        giúp tôi lấy lại giấc ngủ no tròn, giúp sức khỏe và nhan
                        sắc của tôi trở lại như xưa." - NSƯT Hoàng Lan chia sẻ.
                      </div>
                    </div>
                  </div>
                  <div class="bm-nhathuoc">
                    <p class="normal-title">
                      SIX.am đã có mặt tại hơn 1000 điểm bán NT lớn và uy tín
                      trên cả nước
                    </p>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/h42I1kjv-iw"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Quảng Ninh
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/0cq0AeID6es"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Đà Nẵng
                      </p>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/mv6YSGPwLxw"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Bắc Giang
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/FyYiy7O8W4s"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                      <div class="clearfix"></div>
                      <p class="sub-img" style="margin-top: 10px">
                        Điểm bán SIX.am tại Bắc Ninh
                      </p>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="bm-camket">
                    <p class="bold-title">SIX.am cam kết</p>
                    <div class="col-md-3 col-sm-6 col-xs-6 ck-left">
                      <div class="single-camket">
                        <p class="ck-title">Chất lượng</p>
                        <p class="ck-icon icon-star">
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                          <i class="fa-solid fa-star"></i>
                        </p>
                        <p>
                          Sản phẩm được BỘ Y TẾ cấp phép, dựa trên bằng Độc
                          quyền sáng chế cấp quốc gia
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-6 ck-right">
                      <div class="single-camket">
                        <p class="ck-title">Hiệu quả</p>
                        <p class="ck-icon">
                          <i class="fas fa-thumbs-up"></i>
                        </p>
                        <p>
                          Có hiệu quả rõ rệt khi thử nghiệm lâm sàng hoạt chất
                          trên 100 BN mất ngủ mãn tính tại Quân Y Viện 103
                        </p>
                      </div>
                    </div>
                    <div class="hidden-md hidden-lg clearfix"></div>
                    <div class="col-md-3 col-sm-6 col-xs-6 ck-left">
                      <div class="single-camket">
                        <p class="ck-title">Chăm sóc</p>
                        <p class="ck-icon">
                          <i class="fa-solid fa-phone-volume"></i>
                        </p>
                        <p>
                          Chuyên gia tư vấn chuyên sâu, chăm sóc trước và trong
                          quá trình sử dụng sản phẩm
                        </p>
                      </div>
                    </div>
                    <div class="col-md-3 col-sm-6 col-xs-6 ck-right">
                      <div class="single-camket">
                        <p class="ck-title">Giao hàng</p>
                        <p class="ck-icon">
                          <i class="fas fa-truck"></i>
                        </p>
                        <p>
                          Miễn phí giao hàng toàn quốc khi mua theo liệu trình,
                          kiểm tra trước khi nhận hàng
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="contact-box">
                    <div class="w-100 box-wrap">
                      <p>
                        <i class="fa-solid fa-phone"></i>
                        Gọi ngay Hotline
                        <a href="tel:0363166357">0363.166.357</a>
                        hoặc để lại số điện thoại để Chuyên gia tư vấn liệu
                        trình sử dụng phù hợp và hướng dẫn mua hàng tại các nhà
                        thuốc gần nhất
                      </p>
                      <form
                        action="/#wpcf7-f15-o1"
                        method="post"
                        class="wpcf7-form init"
                        novalidate="novalidate"
                        data-status="init"
                      >
                        <div class="w-70 contact-left">
                          <span class="wpcf7-form-control-wrap sdt"
                            ><input
                              type="number"
                              name="sdt"
                              value=""
                              min="99999999"
                              max="9999999999"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Số điện thoại"
                              v-model="formRegisterIntro.PhoneNumberIntro"
                          /></span>
                        </div>
                        <div
                          class="btn-advice-footer"
                          @click="createWebRegisterIntro"
                        >
                          <span role="status" aria-hidden="true"></span>
                          Đăng ký tư vấn
                        </div>
                        <p
                          v-if="isShowWarning"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentWarning }}
                        </p>
                        <p
                          v-if="isShowSuccess"
                          class="mt-2"
                          style="
                            color: #122b4d;
                            margin-bottom: 16px;
                            margin-top: 16px;
                          "
                        >
                          {{ contentSuccess }}
                        </p>
                      </form>
                    </div>
                  </div>
                  <div class="bm-cauhoi">
                    <p class="normal-title">Câu hỏi thường gặp</p>
                    <div class="single-cauhoi">
                      <div class="bm-ask">
                        <div class="col-xs-3 ask-icon">
                          <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="col-xs-9 ask-wrap">
                          Uống SIX.am bao lâu thì ngủ được?
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="bm-ans">
                        <div class="col-xs-9 ans-wrap">
                          SIX.am có hoạt chất chính là Rotundin sulfat được bào
                          chế dưới dạng ống nước nên sinh khả dụng cao. Do đó
                          SIX.am có tác dụng ngay từ những ngày đầu sử dụng
                        </div>
                        <div class="col-xs-3 ask-icon">
                          <i class="far fa-comments"></i>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="border-dashed"></div>
                    <div class="single-cauhoi">
                      <div class="bm-ask">
                        <div class="col-xs-3 ask-icon">
                          <i class="far fa-question-circle"></i>
                        </div>
                        <div class="col-xs-9 ask-wrap">
                          Nên uống SIX.am vào lúc nào?
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="bm-ans">
                        <div class="col-xs-9 ans-wrap">
                          Do sinh khả dụng nhanh nên Chuyên gia khuyên bạn nên
                          sử dụng SIX.am trước khi ngủ 30-60 phút. Nên sử dụng
                          vào khoảng 10h tối để có giấc ngủ sinh lý tự nhiên
                          hiệu quả nhất
                        </div>
                        <div class="col-xs-3 ask-icon"></div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="border-dashed"></div>
                    <div class="single-cauhoi">
                      <div class="bm-ask">
                        <div class="col-xs-3 ask-icon">
                          <i class="far fa-question-circle"></i>
                        </div>
                        <div class="col-xs-9 ask-wrap">
                          SIX.am có phải thuốc tây không?
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="bm-ans">
                        <div class="col-xs-9 ans-wrap">
                          SIX.am là thực phẩm bảo vệ sức khỏe đã được BỘ Y TẾ
                          cấp phép. SIX.am có thành phần tự nhiên gồm các dược
                          chất quý, an toàn, không tác dụng phụ. SIX.am không
                          phải thuốc tây.
                        </div>
                        <div class="col-xs-3 ask-icon">
                          <i class="far fa-comments"></i>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="border-dashed"></div>
                    <div class="single-cauhoi">
                      <div class="bm-ask">
                        <div class="col-xs-3 ask-icon">
                          <i class="far fa-question-circle"></i>
                        </div>
                        <div class="col-xs-9 ask-wrap">
                          Thời gian sử dụng bao lâu?
                        </div>
                      </div>
                      <div class="clearfix"></div>
                      <div class="bm-ans">
                        <div class="col-xs-9 ans-wrap">
                          SIX.am hỗ trợ ngủ ngon ngay từ những đêm đâu tiên. Để
                          điều chỉnh nhịp sinh học và tạo thói quen ngủ sinh lý
                          đúng giờ, Chuyên gia khuyên bạn nên sử dụng liệu trình
                          2 tháng. Sau khi hình thành thói quen ngủ ổn định, lúc
                          đó bạn có thể ngừng không sử dụng nữa.
                        </div>
                        <div class="col-xs-3 ask-icon">
                          <i class="far fa-comments"></i>
                        </div>
                      </div>
                    </div>
                    <div class="clearfix"></div>
                  </div>
                  <div class="clearfix"></div>
                  <img
                    class="img-footer"
                    style="margin-top: 30px"
                    src="../../../assets/images/hop-1.jpg"
                    title="MẤT NGỦ - MUA SIX.AM NGAY HÔM NAY"
                    alt="MẤT NGỦ - MUA SIX.AM NGAY HÔM NAY"
                  />

                  <div class="bm-form bm-dathang dathang-footer">
                    <p class="form-title">Đặt mua SIX.am</p>
                    <p class="form-info">- Giá bán: 379.000 đồng/hộp 20 ống</p>
                    <p class="form-info">
                      - Sử dụng 1-2 ống/tối trước khi đi ngủ 30-60 phút
                    </p>
                    <p class="form-info">
                      - Rẻ hơn và miễn phí vận chuyển khi mua theo liệu trình:
                    </p>
                    <ul>
                      <li>
                        Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá
                        379.000 đồng (miễn phí ship)
                      </li>
                      <li>
                        Liệu trình 1 tháng: 2 hộp SIX.am có giá 758.000 đồng =>
                        Được giảm 70.000 đồng còn 688.000 đồng (miễn phí ship)
                      </li>
                      <li>
                        Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000 đồng
                        => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1
                        combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu
                        chân răng (miễn phí ship)
                      </li>
                    </ul>
                    <div
                      role="form"
                      class="wpcf7"
                      id="wpcf7-f17-o4"
                      lang="en-US"
                      dir="ltr"
                    >
                      <div class="screen-reader-response">
                        <ul></ul>
                      </div>
                      <form
                        action="/#wpcf7-f17-o4"
                        method="post"
                        class="wpcf7-form init"
                        novalidate="novalidate"
                        data-status="init"
                      >
                        <div style="display: none">
                          <input type="hidden" name="_wpcf7" value="17" />
                          <input
                            type="hidden"
                            name="_wpcf7_version"
                            value="5.3"
                          />
                          <input
                            type="hidden"
                            name="_wpcf7_locale"
                            value="en_US"
                          />
                          <input
                            type="hidden"
                            name="_wpcf7_unit_tag"
                            value="wpcf7-f17-o4"
                          />
                          <input
                            type="hidden"
                            name="_wpcf7_container_post"
                            value="0"
                          />
                          <input
                            type="hidden"
                            name="_wpcf7_posted_data_hash"
                            value=""
                          />
                        </div>
                        <div class="w-100">
                          <span class="wpcf7-form-control-wrap hoten"
                            ><input
                              type="text"
                              name="hoten"
                              value=""
                              size="40"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Họ và tên"
                              v-model="formRegister.FullName"
                          /></span>
                        </div>
                        <div class="w-50 dathang-left">
                          <span class="wpcf7-form-control-wrap sdt"
                            ><input
                              type="number"
                              name="sdt"
                              value=""
                              min="99999999"
                              max="9999999999"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Số điện thoại"
                              v-model="formRegister.PhoneNumber"
                          /></span>
                        </div>
                        <div class="w-50">
                          <span class="wpcf7-form-control-wrap lieutrinh"
                            ><select
                              name="lieutrinh"
                              class="wpcf7-form-control wpcf7-select wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              @change="onChange($event)"
                              v-model="selecteded"
                            >
                              <option value="" disabled selected>
                                Chọn liệu trình
                              </option>
                              <option
                                value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000 đồng (miễn phí ship)"
                              >
                                Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có
                                giá 379.000 đồng (miễn phí ship)
                              </option>
                              <option
                                value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 758.000 đồng => Được giảm 70.000 đồng còn 688.000 đồng (miễn phí ship)"
                              >
                                Liệu trình 1 tháng: 2 hộp SIX.am có giá 758.000
                                đồng => Được giảm 70.000 đồng còn 688.000 đồng
                                (miễn phí ship)
                              </option>
                              <option
                                value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.516.000 đồng => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1 combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu chân răng (miễn phí ship)"
                              >
                                Liệu trình 2 tháng: 4 hộp SIX.am có giá
                                1.516.000 đồng => Được giảm 70.000 đồng còn
                                1.446.000 đồng + tặng 1 combo nước súc miệng và
                                kem đánh răng ngăn ngừa chảy máu chân răng (miễn
                                phí ship)
                              </option>
                            </select></span
                          >
                        </div>
                        <div class="w-100">
                          <span class="wpcf7-form-control-wrap diachi">
                            <textarea
                              name="diachi"
                              cols="40"
                              rows="10"
                              class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Địa chỉ nhận hàng"
                              v-model="formRegister.Note"
                            ></textarea>
                          </span>
                        </div>
                        <p
                          v-if="isShowWarning"
                          class="mt-2"
                          style="color: #fff; margin-bottom: 16px"
                        >
                          {{ contentWarning }}
                        </p>
                        <p
                          v-if="isShowSuccess"
                          class="mt-2"
                          style="color: #fff; margin-bottom: 16px"
                        >
                          {{ contentSuccess }}
                        </p>
                        <div @click="createWebRegister" class="btn-summit">
                          <span
                            role="status"
                            aria-hidden="true"
                            v-if="loadding"
                          ></span>
                          Đặt hàng
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  data() {
    return {
      isShowSuccess: false,
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      isShowWarning: false,
      contentWarning: "Vui lòng điền đủ thông tin!",
      loadding: false,
      selecteded: "",
      disciption: "",
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
      formRegisterIntro: {
        PhoneNumberIntro: "",
        FullNameIntro: "",
        DesctiptionIntro: "",
        NoteIntro: "",
      },
    };
  },
  methods: {
    onChange(event) {
      this.disciption = event.target.value;
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "SIX.am",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
    createWebRegisterIntro() {
      if (this.formRegisterIntro.PhoneNumberIntro.length == 0) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegisterIntro.PhoneNumberIntro[0] != 0 ||
        this.formRegisterIntro.PhoneNumberIntro.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.formRegisterIntro.FullNameIntro = "";
      this.formRegisterIntro.NoteIntro = "";
      this.formRegisterIntro.DesctiptionIntro = "";
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegisterIntro.PhoneNumberIntro,
          ProductID: "SIX.am",
          TypeDoc: "TV",
          FullName: this.formRegisterIntro.FullNameIntro,
          Note: this.formRegisterIntro.NoteIntro,
          Desctiption: this.formRegisterIntro.DesctiptionIntro,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
@import "~@/assets/bootstrap/css/bootstrap.css";
@import "~@/assets/css/less/baomoi.less";
.item-img-introduce {
  height: 60px;
}
.btn-summit {
  text-align: center;
  font-family: "Quicksand Regular" !important;
  font-size: 18px !important;
  padding: 10px;
}

.mt-2 {
  text-align: center !important;
}
.btn-advice-footer {
  font-size: 18px !important;
}
a {
  color: #337ab7;
}
</style>
