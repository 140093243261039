<template>
  <div>
    <div
      style="position: fixed; width: 100%; height: 1000px; z-index: 100"
      v-if="isShowSuccess"
    >
      <div
        style="
          background-color: black;
          opacity: 0.3;
          width: 100%;
          height: 10000px;
          position: absolute;
          margin-top: -200px;
        "
        @click="btClose"
      ></div>
      <div class="container-noti">
        <div class="modal-content-noti">
          <div class="header-content-noti">
            <span class="close-noti" @click="btClose">×</span>
            <p class="modal-title-noti">
              Điểm bán tại {{ dataDistrict.district }}
            </p>
          </div>
          <div class="modal-boby-noti">
            Hiện tại chưa có điểm bán SIX.am tại khu vực này, bạn có thể đặt
            hàng theo 2 cách sau:
            <ul>
              <li>
                Cách 1: Đặt hàng ngay
                <a
                  style="
                    color: red !important;
                    cursor: pointer;
                    font-weight: bold;
                  "
                  @click="showNav"
                  >tại đây</a
                >
                . Free ship COD cho đơn hàng từ 2 hộp
              </li>
              <li>
                Cách 2: Đặt hàng trực tuyến qua Fanpage với nhân viên tư vấn sản
                phẩm 24/7 tại địa chỉ:
                <a style="color: #337ab7" href="https://m.me/quatanggiacngu"
                  >https://m.me/quatanggiacngu</a
                >
              </li>
            </ul>
          </div>
          <div class="modal-footer-noti">
            <button @click="btClose">Đóng</button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-buymodal" v-if="isShowBuyModal">
      <!-- Modal content -->
      <div class="content-buymodal" style="overflow: hidden">
        <a class="close-buymomdal" @click="isShowBuyModal = false">&times;</a>
        <div class="buy-left-img">
          <img src="../../assets/images/hop6am.png" />
        </div>
        <div class="buy-right-img">
          <p class="buy-lead-buymodal">MẤT NGỦ - SIX.AM LÀ ĐỦ!</p>
          <ul>
            <li>
              Liệu trình dùng thử (10 ngày):
              <span>1 hộp SIX.am có giá 379.000 đồng (miễn phí ship) </span>
            </li>
            <li>
              Liệu trình 1 tháng:
              <span
                >2 hộp SIX.am có giá 758.000 đồng => Được giảm 70.000 đồng còn
                688.000 đồng (miễn phí ship)</span
              >
            </li>
            <li>
              Liệu trình 2 tháng:
              <span
                >4 hộp SIX.am có giá 1.516.000 đồng => Được giảm 70.000 đồng còn
                1.446.000 đồng + tặng 1 combo nước súc miệng và kem đánh răng
                ngăn ngừa chảy máu chân răng (miễn phí ship)</span
              >
            </li>
          </ul>
          <div class="modal-muangay">
            <form>
              <div class="form-name">
                <span class=""
                  ><input
                    type="text"
                    name="name"
                    value=""
                    size="40"
                    class="input-name"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Họ và tên"
                    v-model="formRegister.FullName"
                /></span>
              </div>
              <div class="form-number">
                <span class=""
                  ><input
                    type="number"
                    name="number"
                    value=""
                    class="input-number"
                    min="99999999"
                    max="9999999999"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Số điện thoại"
                    v-model="formRegister.PhoneNumber"
                /></span>
              </div>
              <div class="">
                <span class="form-course"
                  ><select
                    name="course"
                    class="input-course"
                    aria-required="true"
                    aria-invalid="false"
                    v-model="selecteded"
                    @change="onChange($event)"
                  >
                    <option value="" disabled selected>Chọn liệu trình</option>
                    <option
                      value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000 đồng (miễn phí ship)"
                    >
                      Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000
                      đồng (miễn phí ship)
                    </option>
                    <option
                      value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000 đồng (miễn phí ship)"
                    >
                      Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000 đồng (miễn
                      phí ship)
                    </option>
                    <option
                      value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.446.000 đồng (miễn phí ship)"
                    >
                      Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.446.000 đồng
                      (miễn phí ship)
                    </option>
                  </select></span
                >
              </div>
              <div class="">
                <span class="form-address">
                  <textarea
                    name="address"
                    cols="40"
                    rows="10"
                    class="input-address"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Địa chỉ nhận hàng"
                    v-model="formRegister.Note"
                  ></textarea>
                </span>
              </div>
              <p
                v-if="isShowWarning"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentWarning }}
              </p>
              <p
                v-if="isShowSuccessform"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentSuccess }}
              </p>
              <div @click="createWebRegister" class="btn-summit">
                <span role="status" aria-hidden="true" v-if="loadding"></span>
                Đặt hàng
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  props: {
    dataDistrict: Object,
  },
  data() {
    return {
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
      isShowHidenotiModal: true,
      isShowSuccessform: false,
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      isShowSuccess: true,
      isShowBuyModal: false,
      isShowSpace: false,
      isShowWarning: false,
      loadding: false,
      selecteded: "",
      disciption: "",
      contentWarning: "Vui lòng điền đủ thông tin!",
      courses: [
        { product: "Liệu trình dùng thử (10 ngày)" },
        { product: "Liệu trình 1 tháng: 560.000 đ" },
        { product: " Liệu trình 2 tháng: 1.120.000đ" },
      ],
    };
  },
  methods: {
    showNav() {
      this.isShowBuyModal = true;
    },
    btClose() {
      this.$emit("btClose", false);
    },
    onChange(event) {
      this.disciption = event.target.value;
    },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "SIX.am",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccessform = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccessform = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.modal-content-noti {
  width: 1000px;
  position: absolute;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background-clip: padding-box;
  right: 0;
  left: 0;
  margin: auto;
  margin-top: 100px;
}
.header-content-noti {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}
.close-noti {
  background-color: white;
  color: red;
  opacity: 1;
  font-size: 40px;
  position: absolute;
  right: 0;
  margin-right: 20px;
  top: 0;
  cursor: pointer;
}
.modal-title-noti {
  font-family: "Quicksand Black";
  font-size: 20px;
}
.modal-boby-noti {
  position: relative;
  padding: 15px;
}
.modal-boby-noti ul {
  margin-bottom: 10px;
  margin-left: 0;
}
.modal-footer-noti {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modal-footer-noti button {
  padding: 6px 12px;
  color: #000000;
  border: #ccc solid 1px;
  border-radius: 3px;
  background-image: linear-gradient(to bottom, #fff 0%, #e0e0e0 100%);
}
.modal-footer-noti button:hover {
  text-decoration: none;
}
.header-inner {
  max-width: 91%;
}
.btn-summit {
  width: 100%;
  float: left;
  margin-bottom: 35px;
  background-color: #cd2653;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  cursor: pointer;
  padding: 9px 0;
  font-family: Mulish !important;
  font-size: 16px !important;
}
.buy-success {
  z-index: 1022 !important;
  border-radius: 8px;
  position: fixed;
  text-align: center;

  overflow: hidden;
  padding: 30px;
  max-width: 400px !important;
  background-color: #122b4d;
}
#icon-success {
  color: white !important;
  font-size: 70px;
}
.buy-success .title {
  font-family: "Quicksand SemiBold";
  font-size: 24px;
  margin-top: 10px;
  color: rgb(245, 223, 80);
}
.buy-success .sub {
  font-family: "Quicksand Medium";
  font-size: 18px;
  color: #fff;
}
.container-buymodal {
  position: fixed;
  z-index: 100;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 3000px;
  right: 0;
  overflow: auto;
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.4);
  float: left;
  text-align: center;
}
.content-buymodal {
  border: solid 3px #ecdf42;
  background: #122b4d;
  width: 80%;
  border-radius: 6px;
  display: inline-block;
}
.close-buymomdal {
  color: white;
  float: right;
  font-size: 29px;
  font-weight: bold;
  opacity: 1;
  margin-top: 15px;
  margin-right: 20px;
  cursor: pointer;
}
.close-buymomdal:hover {
  color: red;
}
.buy-left-img {
  float: left;
  width: 35%;
  margin-top: 20px;
}
.buy-left-img img {
  display: block;
  max-width: 420px;
  margin: 30px auto 0;
  width: 100%;
}
.buy-right-img {
  float: left;
  width: 63.5%;
}
.buy-lead-buymodal {
  color: #ecdf42;
  font-family: "Mulish Black";
  font-size: 20px;
  margin-bottom: 0;
}
.buy-right-img ul {
  color: white;
  font-family: Mulish;
  font-size: 15px;
  margin-left: 10px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.buy-right-img ul li {
  margin-left: 3px;
  font-family: "Mulish Bold";
  text-align: start;
}
.buy-right-img ul li span {
  color: #ecdf42;
}
.form-name {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.input-name {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-number {
  padding-right: 10px;
  float: left;
  width: 20%;
  margin-bottom: 15px;
}
.input-number {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-course {
  float: left;
  width: 80%;
  margin-bottom: 10px;
}
.input-course {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.input-course option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
}
.form-address {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.input-address {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-submit {
  width: 100%;
  float: left;
  margin-bottom: 35px;
}
.btn-buydialog {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
@media (max-width: 1350px) {
  .content-buymodal {
    width: 90%;
  }
  .close-buymomdal {
    margin-right: 10px;
  }
}
@media (max-width: 950px) {
  .buy-left-img {
    float: center;
    display: inline-block;
    width: 100% !important;
    text-align: center;
    margin-top: 0px;
  }
  .buy-left-img img {
    display: block;
    max-width: 290px !important;
    margin: 30px auto 0;
    width: 100%;
  }
  .buy-right-img {
    width: 100%;
  }
  .buy-right-img ul {
    margin: 0 0 1.5rem 3rem;
  }
  .modal-muangay {
    padding-left: 10px;
    padding-right: 10px;
  }
  .input-address {
    height: 40px;
  }
  .container-buymodal {
    padding-top: 100px;
  }
}
@media (max-width: 600px) {
  .container-buymodal {
    padding-top: 70px;
  }
  .content-buymodal {
    width: 100%;
  }
  .buy-right-img ul li {
    width: 95%;
  }
}
@media (max-width: 420px) {
  .contain_close_nav {
    right: -15px;
  }
  .container-buymodal {
    padding-top: 50px;
  }
  .buy-right-img ul {
    margin: 0;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .buy-left-img img {
    margin: 0;
    display: inline-block;
  }
  .modal-muangay {
    padding-left: 10px;
    padding-right: 10px;
  }
  .form-name {
    margin-bottom: 4px;
  }
  .form-number {
    width: 100%;
    padding-right: 0;
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
  .form-course {
    width: 100%;
    padding-right: 0;
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
  .input-course {
    margin-bottom: 0;
  }
  .form-address {
    margin-bottom: 0;
  }
  .form-submit {
    margin-bottom: 20px;
  }
}
@media (max-width: 1023px) {
  .modal-content-noti {
    width: 80%;
  }
}
@media (max-width: 800px) {
  .modal-content-noti {
    width: 97%;
  }
}
</style>
