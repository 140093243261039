<template>
  <div>
    <div id="header_navbar">
      <header id="site-header" class="header-footer-group" role="banner">
        <div class="container-fluid">
          <div class="row">
            <div class="header-inner section-inner">
              <div class="header-titles-wrapper col-md-3 col-md-push-1">
                <div class="header-titles" id="header-titles">
                  <a href="/">
                    <img
                      class="#items-direction"
                      src="../../../assets/images/logo.png"
                    />
                  </a>
                </div>
                <!-- .header-titles -->
                <a
                  class="bars-menu toggle nav-toggle mobile-nav-toggle"
                  data-toggle-target=".menu-modal"
                  data-toggle-body-class="showing-menu-modal"
                  aria-expanded="false"
                  data-set-focus=".close-nav-toggle"
                >
                  <span class="toggle-inner">
                    <span
                      class="toggle-icon"
                      @click="
                        (isShowNavRespone = true), (isShowNavSpace = true)
                      "
                    >
                      <i class="fas fa-bars"></i>
                    </span> </span></a
                ><!-- .nav-toggle -->
              </div>
              <!-- .header-titles-wrapper -->

              <div class="header-navigation-wrapper col-xs-8">
                <nav
                  class="primary-menu-wrapper"
                  aria-label=""
                  role="navigation"
                >
                  <ul class="primary-menu reset-list-style">
                    <li
                      class="items_navbar"
                      v-for="(item, index) in menuLst"
                      :key="index"
                    >
                      <!-- <a href="/">Trang chủ</a> -->
                      <router-link :to="item.link"
                        ><span
                          :class="isButton == item.key ? 'isActive' : ''"
                          >{{ item.label }}</span
                        ></router-link
                      >
                    </li>
                    <!-- <li class="items_navbar" @click="curentitems" ><a href="/cong-dung">Công dụng</a></li>
                            <li class="items_navbar" ><a href="/blogs">Trị mất ngủ</a></li>
                            <li class="items_navbar" ><a href="/diem-ban">Điểm bán</a></li> -->
                    <li>
                      <a
                        class="open-buy open-buy-modal"
                        @click="showNav"
                        style="color: #f5df50 !important; cursor: pointer"
                      >
                        <span>
                          <i class="fa-solid fa-cart-shopping"></i>
                        </span>
                        <span> Đặt hàng ngay </span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <!-- .primary-menu-wrapper -->
              </div>
              <!-- .header-navigation-wrapper -->
            </div>
            <!-- .header-inner -->
          </div>
        </div>
      </header>
      <!-- #site-header -->

      <!-- <div>
        <div id="buymodal" class="buymodal" v-if="isShowBuyModal">
          <div class="modal-content" style="overflow: hidden">
            <a class="close" @click="isShowBuyModal = false">&times;</a>
            <div class="buy-left">
              <img src="../../../assets/images/hop6am.png" />
            </div>
            <div class="buy-right">
              <p class="buy-lead">MẤT NGỦ - SIX.AM LÀ ĐỦ!</p>
              <ul>
                <li>
                  Liệu trình dùng thử (10 ngày):
                  <span>1 hộp SIX.am có giá 379.000 đồng (miễn phí ship) </span>
                </li>
                <li>
                  Liệu trình 1 tháng:
                  <span>2 hộp SIX.am có giá 758.000 đồng => Được giảm 70.000 đồng còn 688.000 đồng (miễn phí ship)</span>
                </li>
                <li>
                  Liệu trình 2 tháng:
                  <span
                    >4 hộp SIX.am có giá 1.516.000 đồng => Được giảm 70.000 đồng còn 1.446.000 đồng + tặng 1 combo nước súc miệng và kem đánh răng ngăn ngừa chảy máu chân răng (miễn phí ship)</span
                  >
                </li>
              </ul>
              <div class="modal-muangay">
                <form
                  action="/#wpcf7-f17-o4"
                  method="post"
                  class="wpcf7-form init"
                  novalidate="novalidate"
                  data-status="init"
                >
                  <div style="display: none">
                    <input type="hidden" name="_wpcf7" value="17" />
                    <input type="hidden" name="_wpcf7_version" value="5.3" />
                    <input type="hidden" name="_wpcf7_locale" value="en_US" />
                    <input
                      type="hidden"
                      name="_wpcf7_unit_tag"
                      value="wpcf7-f17-o4"
                    />
                    <input
                      type="hidden"
                      name="_wpcf7_container_post"
                      value="0"
                    />
                    <input
                      type="hidden"
                      name="_wpcf7_posted_data_hash"
                      value=""
                    />
                  </div>
                  <div class="w-100">
                    <span class="wpcf7-form-control-wrap hoten"
                      ><input
                        type="text"
                        name="hoten"
                        value=""
                        size="40"
                        class="
                          wpcf7-form-control
                          wpcf7-text
                          wpcf7-validates-as-required
                        "
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Họ và tên"
                    /></span>
                  </div>
                  <div class="w-50 dathang-left">
                    <span class="wpcf7-form-control-wrap sdt"
                      ><input
                        type="number"
                        name="sdt"
                        value=""
                        class="
                          wpcf7-form-control
                          wpcf7-number
                          wpcf7-validates-as-required
                          wpcf7-validates-as-number
                        "
                        min="99999999"
                        max="9999999999"
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Số điện thoại"
                    /></span>
                  </div>
                  <div class="w-50">
                    <span class="wpcf7-form-control-wrap lieutrinh"
                      ><select
                        name="lieutrinh"
                        class="
                          wpcf7-form-control
                          wpcf7-select
                          wpcf7-validates-as-required
                        "
                        aria-required="true"
                        aria-invalid="false"
                      >
                        <option value="Chọn liệu trình">Chọn liệu trình</option>
                        <option value="Liệu trình dùng thử (10 ngày)">
                          Liệu trình dùng thử (10 ngày)
                        </option>
                        <option value="Liệu trình 1 tháng: 560.000 đ">
                          Liệu trình 1 tháng: 560.000 đ
                        </option>
                        <option value="Liệu trình 2 tháng: 1.120.000đ">
                          Liệu trình 2 tháng: 1.120.000đ
                        </option>
                      </select></span
                    >
                  </div>
                  <div class="w-100">
                    <span class="wpcf7-form-control-wrap diachi">
                      <textarea
                        name="diachi"
                        cols="40"
                        rows="10"
                        class="
                          wpcf7-form-control
                          wpcf7-textarea
                          wpcf7-validates-as-required
                        "
                        aria-required="true"
                        aria-invalid="false"
                        placeholder="Địa chỉ nhận hàng"
                      ></textarea>
                    </span>
                  </div>
                  <div class="w-100">
                    <input
                      type="submit"
                      value="Đặt hàng"
                      class="wpcf7-form-control wpcf7-submit f-button"
                    /><span class="ajax-loader"></span>
                  </div>
                  <div class="wpcf7-response-output" aria-hidden="true"></div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <nav class="container_navmobile" v-if="isShowNavRespone">
        <div role="navigation" class="navigation_navmobile">
          <div id="menuToggle_navmobile">
            <span class="items_close_nav">
              <li class="contain_close_nav">
                <span
                  class="closenav_btn"
                  @click="(isShowNavRespone = false), (isShowNavSpace = false)"
                >
                  <a class="fa-solid fa-xmark" id="closenav"></a>
                </span>
              </li>
            </span>
            <ul id="menu_navmobile">
              <li
                class="items_li"
                v-for="(item, index) in menuLst"
                :key="index"
              >
                <!-- <a href="/">Trang chủ</a> -->
                <router-link :to="item.link"
                  ><span
                    @click="
                      (isShowNavRespone = false), (isShowNavSpace = false)
                    "
                    >{{ item.label }}</span
                  ></router-link
                >
              </li>
              <li class="items_li">
                <a
                  class="items_navmobile"
                  id="items_navorder"
                  @click="
                    (isShowBuyModal = true),
                      (isShowNavRespone = false),
                      (isShowNavSpace = false)
                  "
                  >Đặt hàng ngay</a
                >
              </li>
              <li class="items_li">
                <a href="/">
                  <img
                    id="logo-img-nav"
                    src="../../../assets/images/logo.png"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div
        class="close-space-nav"
        v-if="isShowNavSpace"
        @click="(isShowNavRespone = false), (isShowNavSpace = false)"
      ></div>
    </div>
    <div class="container-buymodal" v-if="isShowBuyModal">
      <!-- Modal content -->
      <div class="bg-buy"></div>
      <div class="content-buymodal">
        <a class="close-buymomdal" @click="isShowBuyModal = false">&times;</a>
        <div class="buy-left-img">
          <img src="../../../assets/images/hop6am.png" />
        </div>
        <div class="buy-right-img">
          <p class="buy-lead-buymodal">MẤT NGỦ - SIX.AM LÀ ĐỦ!</p>
          <ul>
            <li>
              Liệu trình dùng thử (10 ngày):
              <span>1 hộp SIX.am có giá 379.000 đồng (miễn phí ship) </span>
            </li>
            <li>
              Liệu trình 1 tháng:
              <span
                >2 hộp SIX.am có giá 758.000 đồng => Được giảm 70.000 đồng còn
                688.000 đồng (miễn phí ship)</span
              >
            </li>
            <li>
              Liệu trình 2 tháng:
              <span
                >4 hộp SIX.am có giá 1.516.000 đồng => Được giảm 70.000 đồng còn
                1.446.000 đồng + tặng 1 combo nước súc miệng và kem đánh răng
                ngăn ngừa chảy máu chân răng (miễn phí ship)</span
              >
            </li>
          </ul>
          <div class="modal-muangay">
            <form>
              <div class="form-name">
                <span class=""
                  ><input
                    type="text"
                    name="name"
                    value=""
                    size="40"
                    class="input-name"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Họ và tên"
                    v-model="formRegister.FullName"
                /></span>
              </div>
              <div class="form-number">
                <span class=""
                  ><input
                    type="number"
                    name="number"
                    value=""
                    class="input-number"
                    min="99999999"
                    max="9999999999"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Số điện thoại"
                    v-model="formRegister.PhoneNumber"
                /></span>
              </div>
              <div class="">
                <span class="form-course"
                  ><select
                    name="course"
                    class="input-course"
                    aria-required="true"
                    aria-invalid="false"
                    v-model="selecteded"
                    @change="onChange($event)"
                  >
                    <option value="" disabled selected>Chọn liệu trình</option>
                    <option
                      value="Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000 đồng (miễn phí ship)"
                    >
                      Liệu trình dùng thử (10 ngày): 1 hộp SIX.am có giá 379.000
                      đồng (miễn phí ship)
                    </option>
                    <option
                      value="Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000 đồng (miễn phí ship)"
                    >
                      Liệu trình 1 tháng: 2 hộp SIX.am có giá 688.000 đồng (miễn
                      phí ship)
                    </option>
                    <option
                      value=" Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.446.000 đồng (miễn phí ship)"
                    >
                      Liệu trình 2 tháng: 4 hộp SIX.am có giá 1.446.000 đồng
                      (miễn phí ship)
                    </option>
                  </select></span
                >
              </div>
              <div class="">
                <span class="form-address">
                  <textarea
                    name="address"
                    cols="40"
                    rows="10"
                    class="input-address"
                    aria-required="true"
                    aria-invalid="false"
                    placeholder="Địa chỉ nhận hàng"
                    v-model="formRegister.Note"
                  ></textarea>
                </span>
              </div>
              <p
                v-if="isShowWarning"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentWarning }}
              </p>
              <p
                v-if="isShowSuccess"
                class="mt-2"
                style="color: #fff; margin-bottom: 16px"
              >
                {{ contentSuccess }}
              </p>
              <div @click="createWebRegister" class="btn-summit">
                <span role="status" aria-hidden="true" v-if="loadding"></span>
                Đặt hàng
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="buy-success" v-else>
      <i class="fa-solid fa-circle-check" id="icon-success"></i>
      <p class="title">Thành công</p>
      <p class="sub">
        Cám ơn quý khách! Nhân viên CSKH sẽ sớm liên lạc lại với quý khách trong
        thời gian sớm nhất!
      </p>
    </div>
    <div
      style="
        width: 100%;
        height: 10000px;
        background-color: black;
        opacity: 0.2;
        position: fixed;
        margin-top: -200px;
        z-index: 100;
      "
    ></div> -->
  </div>
</template>

<script>
import { CreateWebRegister } from "@/api/place";
export default {
  components: {},
  props: ["isButton"],
  data() {
    return {
      isShowSuccess: false,
      contentSuccess:
        "Đăng kí thành công, nhân viên CSKH sẽ sớm liên hệ với quý khách!",
      isShowNavRespone: false,
      isShowNavSpace: false,
      isShowBuyModal: false,
      isShowWarning: false,
      loadding: false,
      selecteded: "",
      disciption: "",
      contentWarning: "Vui lòng điền đủ thông tin!",
      courses: [
        { product: "Liệu trình dùng thử (10 ngày)" },
        { product: "Liệu trình 1 tháng: 560.000 đ" },
        { product: " Liệu trình 2 tháng: 1.120.000đ" },
      ],
      menuLst: [
        {
          value: 1,
          label: "Trang chủ",
          key: "",
          link: "/",
        },
        {
          value: 2,
          label: "Công dụng",
          link: "/cong-dung",
          key: "congdung",
        },
        {
          value: 3,
          label: "Trị mất ngủ",
          link: "/blogs",
          key: "blogs",
        },
        // {
        //   value: 4,
        //   label: "Điểm bán",
        //   link: "/diem-ban",
        //   key: "diemban",
        // },
      ],
      formRegister: {
        PhoneNumber: "",
        FullName: "",
        Desctiption: "",
        Note: "",
      },
    };
  },
  methods: {
    onChange(event) {
      this.disciption = event.target.value;
    },
    showNav() {
      this.isShowBuyModal = true;
    },
    // curentitems() {
    //   var btnContainer = document.getElementsByClassName(
    //     "primary-menu reset-list-style"
    //   );

    //   // Get all buttons with class="btn" inside the container
    //   var btns = btnContainer.getElementById("items_navbar");
    //   // Loop through the buttons and add the active class to the current/clicked button
    //   for (var i = 0; i < btns.length; i++) {
    //     btns[i].addEventListener("click", function () {
    //       var current = document.getElementById("activeitems");
    //       // If there's no active class
    //       if (current.length > 0) {
    //         current[0].className = current[0].className.replace(
    //           " activeitems",
    //           ""
    //         );
    //       }
    //       // Add the active class to the current/clicked button
    //       this.className += " activeitems";
    //     });
    //   }
    // },
    createWebRegister() {
      if (
        this.formRegister.FullName.length == 0 ||
        this.formRegister.PhoneNumber.length == 0 ||
        this.disciption.length == 0 ||
        this.formRegister.Note.length == 0
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Vui lòng điền đủ thông tin!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      if (
        this.formRegister.PhoneNumber[0] != 0 ||
        this.formRegister.PhoneNumber.length != 10
      ) {
        this.isShowWarning = true;
        this.contentWarning = "Số điện thoại không hợp lệ!";
        setTimeout(() => {
          this.isShowWarning = false;
        }, 3000);
        return;
      }
      this.loadding = true;
      this.formRegister.Note = this.formRegister.Note + " (six.am)";
      CreateWebRegister({
        WebRegisterInfo: {
          PhoneNumber: this.formRegister.PhoneNumber,
          ProductID: "SIX.am",
          TypeDoc: "DK",
          FullName: this.formRegister.FullName,
          Note: this.formRegister.Note,
          Desctiption: this.disciption,
          LocationRegister: 0,
        },
      }).then((res) => {
        this.loadding = false;

        if (res.data.RespCode == 0) {
          this.isShowSuccess = true;
          setTimeout(() => {
            this.isShowBuyModal = false;
            this.isShowSuccess = false;
          }, 3000);
        } else {
          this.isShowWarning = true;
          this.contentWarning = "Có lỗi xảy ra vui lòng thử lại!";
          setTimeout(() => {
            this.isShowWarning = false;
          }, 3000);
        }
      });
    },
  },
};
</script>

<style>
@import "~@/assets/css/lp6am.css";
@import "~@/assets/css/style-default.css";
@import "~@/assets/css/less/baomoi.less";
@import "~@/assets/css/less/header.less";
@import "~@/assets/css/less/homepage.less";
@import "~@/assets/css/less/diemban.less";
@import "~@/assets/css/less/diemban.css";
@import "~@/assets/css/navmobile.scss";
.header-inner {
  max-width: 91%;
}
.btn-summit {
  width: 100%;
  float: left;
  margin-bottom: 35px;
  background-color: #cd2653;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  cursor: pointer;
  padding: 9px 0;
  font-family: Mulish !important;
  font-size: 16px !important;
}
.buy-success {
  z-index: 1022 !important;
  border-radius: 8px;
  position: fixed;
  text-align: center;

  overflow: hidden;
  padding: 30px;
  max-width: 400px !important;
  background-color: #122b4d;
}
#icon-success {
  color: white !important;
  font-size: 70px;
}
.buy-success .title {
  font-family: "Quicksand SemiBold";
  font-size: 24px;
  margin-top: 10px;
  color: rgb(245, 223, 80);
}
.buy-success .sub {
  font-family: "Quicksand Medium";
  font-size: 18px;
  color: #fff;
}
.container-buymodal {
  position: fixed;
  z-index: 1000;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 3000px;
  right: 0;
  overflow: auto;
}
.bg-buy {
  position: fixed;
  z-index: -1 !important;
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}
.content-buymodal {
  z-index: 101;
  border: solid 3px #ecdf42;
  margin: auto;
  background: #122b4d;
  width: 80%;
  overflow: hidden;
  border-radius: 6px;
  /* display: inline-block; */
}
.close-buymomdal {
  color: white;
  float: right;
  font-size: 29px;
  font-weight: bold;
  opacity: 1;
  margin-top: 15px;
  margin-right: 20px;
  cursor: pointer;
}
.close-buymomdal:hover {
  color: red;
}
.buy-left-img {
  float: left;
  width: 35%;
  margin-top: 20px;
}
.buy-left-img img {
  display: block;
  max-width: 420px;
  margin: 30px auto 0;
  width: 100%;
}
.buy-right-img {
  float: left;
  width: 63.5%;
}
.buy-lead-buymodal {
  color: #ecdf42;
  font-family: "Mulish Black";
  font-size: 20px;
  margin-bottom: 0;
}
.buy-right-img ul {
  color: white;
  font-family: Mulish;
  font-size: 15px;
  margin-left: 10px;
  line-height: 1.2;
  margin-bottom: 15px;
}
.buy-right-img ul li {
  margin-left: 3px;
  font-family: "Mulish Bold";
  text-align: start;
}
.buy-right-img ul li span {
  color: #ecdf42;
}
.form-name {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
.input-name {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-number {
  padding-right: 10px;
  float: left;
  width: 19%;
  margin-bottom: 15px;
}
.input-number {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-course {
  float: left;
  width: 81%;
  margin-bottom: 10px;
}

.input-course {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.input-course option:hover {
  box-shadow: 0 0 10px 100px #000 inset;
}
.form-address {
  width: 100%;
  float: left;
  margin-bottom: 10px;
}
.input-address {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
.form-submit {
  width: 100%;
  float: left;
  margin-bottom: 35px;
}
.btn-buydialog {
  font-family: Mulish !important;
  font-size: 16px !important;
  margin-bottom: 5px;
  padding: 7px !important;
}
@media (max-width: 1350px) {
  .content-buymodal {
    width: 90%;
  }
  .close-buymomdal {
    margin-right: 10px;
  }
}
@media (max-width: 950px) {
  .buy-left-img {
    float: center;
    display: inline-block;
    width: 100% !important;
    text-align: center;
    margin-top: 0px;
  }
  .buy-left-img img {
    display: block;
    max-width: 290px !important;
    margin: 30px auto 0;
    width: 100%;
  }
  .buy-right-img {
    width: 100%;
  }
  .buy-right-img ul {
    margin: 0 0 1.5rem 3rem;
  }
  .modal-muangay {
    padding-left: 10px;
    padding-right: 10px;
  }
  .input-address {
    height: 40px;
  }
  .container-buymodal {
    padding-top: 100px;
  }
}
@media (max-width: 650px) {
  .items_li > a > span {
    font-size: 15px;
  }
  #items_navorder {
    font-size: 15px;
  }
}
@media (max-width: 600px) {
  .container-buymodal {
    padding-top: 70px;
  }
  .content-buymodal {
    width: 100%;
  }
  .buy-right-img ul li {
    width: 95%;
  }
}
@media (max-width: 420px) {
  .contain_close_nav {
    right: -15px;
  }
  .container-buymodal {
    padding-top: 50px;
  }
  .buy-right-img ul {
    margin: 0;
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .buy-left-img img {
    margin: 0;
    display: inline-block;
  }
  .modal-muangay {
    padding-left: 10px;
    padding-right: 10px;
  }
  .form-name {
    margin-bottom: 4px;
  }
  .form-number {
    width: 100%;
    padding-right: 0;
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
  .form-course {
    width: 100%;
    padding-right: 0;
    margin-top: 0 !important;
    margin-bottom: 4px;
  }
  .input-course {
    margin-bottom: 0;
  }
  .form-address {
    margin-bottom: 0;
  }
  .form-submit {
    margin-bottom: 20px;
  }
}
</style>
